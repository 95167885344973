import React, { FC, memo, useCallback, useState } from 'react';
import { Loader } from '@/ui/components/Loader';
import { Form as FormUI, Row, Col, notify } from '@/ui';
import { useTranslate } from '@/translations';
import { useCreateDocumentType } from '@/api/DocumentManagement';

import { Button, Modal } from 'antd';
import { AddDocumentTypeModalProps } from './types';
import { Title } from './AddDocumentTypeModal.styled';

export const AddDocumentTypeModal: FC<AddDocumentTypeModalProps> = memo(
  ({ isOpen, closeModal }) => {
    const { t } = useTranslate();
    const { mutateAsync: createDocumentType } = useCreateDocumentType();

    const [isLoading, setIsLoading] = useState(false);
    const [documentTypeCode, setDocumentTypeCode] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [isCustomerPreviewRestricted, setIsCustomerPreviewRestricted] =
      useState(false);

    const handleDocumentTypeCodeChange = useCallback(
      (e) => setDocumentTypeCode(e.target.value),
      [],
    );

    const handleDocumentTypeChange = useCallback(
      (e) => setDocumentType(e.target.value),
      [],
    );

    const toggleCustomerPreviewRestricted = useCallback(
      (e) => setIsCustomerPreviewRestricted(e.target.checked),
      [],
    );

    const handleFormSave = useCallback(async () => {
      if (!documentTypeCode || !documentType) {
        notify(
          {
            message: `${t('documentTypeCode')} and ${t(
              'documentType',
            )} fields are required`,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );

        return;
      }

      try {
        setIsLoading(true);
        await createDocumentType({
          documentTypeCode,
          documentType,
          isCustomerPreviewRestricted,
        });
        closeModal();
      } catch (e) {
        notify(
          {
            // @ts-ignore
            message: e.response.data.Message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );
      } finally {
        setIsLoading(false);
      }
    }, [
      t,
      createDocumentType,
      closeModal,
      documentTypeCode,
      documentType,
      isCustomerPreviewRestricted,
    ]);

    return (
      <Modal open={isOpen} onCancel={closeModal} footer={null}>
        <Title>{t('addNewDocumentType')}</Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Row>
            <Col lg={6}>
              <FormUI>
                <FormUI.Group as={Row} className="mb-3">
                  <FormUI.Label column sm={5}>
                    {t('documentTypeCode')}
                  </FormUI.Label>
                  <Col sm={7}>
                    <FormUI.Control
                      value={documentTypeCode}
                      onChange={handleDocumentTypeCodeChange}
                    />
                  </Col>
                </FormUI.Group>
                <FormUI.Group as={Row} className="mb-3">
                  <FormUI.Label column sm={5}>
                    {t('documentType')}
                  </FormUI.Label>
                  <Col sm={7}>
                    <FormUI.Control
                      value={documentType}
                      onChange={handleDocumentTypeChange}
                    />
                  </Col>
                </FormUI.Group>
                <FormUI.Group as={Row} className="mb-3">
                  <FormUI.Label column sm={5}>
                    {t('isCustomerPreviewRestricted')}
                  </FormUI.Label>
                  <Col sm={7} className="d-flex align-items-center">
                    <FormUI.Check
                      className="dx-custom-switch"
                      checked={isCustomerPreviewRestricted}
                      onChange={toggleCustomerPreviewRestricted}
                    />
                  </Col>
                </FormUI.Group>
                <FormUI.Group as={Row}>
                  <Col sm={5} />
                  <Col sm={7}>
                    <Button type="primary" onClick={handleFormSave}>
                      {t('saveChanges')}
                    </Button>
                  </Col>
                </FormUI.Group>
              </FormUI>
            </Col>
          </Row>
        )}
      </Modal>
    );
  },
);
