import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from '@/translations';
import { Loader } from '@/ui/components/Loader';
import { PageContainer } from '@/ui/components/Page';
import {
  useInspectionsVehiclesNotes,
  useUpdateInspectionsVehiclesNotes,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { Button, Input } from 'antd';
import { InspectionPageTab } from '../types';

export const Notes: FC<InspectionPageTab> = () => {
  const { id } = useParams();
  const { t } = useTranslate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [comment, setComment] = useState('');

  const { isLoading, data } = useInspectionsVehiclesNotes(Number(id));
  const { mutate } = useUpdateInspectionsVehiclesNotes(Number(id));

  const note = data?.entities[0];

  useEffect(() => {
    if (note) {
      setComment(note?.comment);
    }
  }, [note]);

  const handleSaveClick = () => {
    if (note?.id) {
      mutate({
        id: note?.id,
        comment: {
          isChanged: true,
          value: comment,
        },
        commentType: {
          isChanged: false,
        },
      });
    }

    setIsDisabled(true);
  };

  const handleCancelClick = () => {
    setComment(note?.comment ?? '');
    setIsDisabled(true);
  };

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Input.TextArea
        rows={5}
        value={comment}
        disabled={isDisabled}
        onChange={(e) => setComment(e.target.value)}
      />

      <div className="d-flex justify-content-end gap-3">
        <Button type="primary" onClick={handleSaveClick}>
          {t('save')}
        </Button>

        <Button
          onClick={isDisabled ? () => setIsDisabled(false) : handleCancelClick}
        >
          {t(isDisabled ? 'edit' : 'cancel')}
        </Button>
      </div>
    </PageContainer>
  );
};
