import React, { FC, useCallback, useState } from 'react';

import { FunctionalFeature } from '@/types';
import {
  useAssignGBAUserToTenant,
  useCreateUserInTenant,
  useDeleteUser,
  useGBAUsers,
  useInviteGBAUser,
  useInviteUser,
  useTenantGBAUsers,
  useTenantUsers,
  useUnassignGBAUserToTenant,
} from '@/api/Users';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections';
import { useGBARoles, useRoles } from '@/api/Roles';
import {
  useTenantFunctionalFeatures,
  useToggleDisableFunctionalFeatures,
} from '@/api/FunctionalFeatures';
import { AddUserData } from '@/modules/TenantManagement/components/tabs/Users/types';

import { Card, Tabs } from 'antd';
import { UsersTab } from './Users/Users';
import { FeaturesTab } from './Features/Features';
import { formatCreateUserData } from './helpers';
import { GBAUsersTab } from './GBAUsers/GBAUsers';

export const MasterDetail: FC<{ tenantId: number }> = ({ tenantId }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState('0');

  const { data: tenantGBAUsersData } = useTenantGBAUsers(tenantId, {
    enabled: selectedTabIndex === '0',
  });
  const { data: tenantUsersData } = useTenantUsers(tenantId, {
    enabled: selectedTabIndex === '1',
  });
  const { data: inspectorsData } = useInspectionsVehiclesInspectors({
    enabled: ['0', '1'].includes(selectedTabIndex),
  });
  const { data: tenantFeaturesData } = useTenantFunctionalFeatures(tenantId, {
    enabled: selectedTabIndex === '2',
  });

  const { data: gbaUsers } = useGBAUsers({
    enabled: selectedTabIndex === '0',
  });

  const { data: gbaRolesData } = useGBARoles({
    enabled: selectedTabIndex === '0',
  });
  const { data: rolesData } = useRoles({
    enabled: selectedTabIndex === '1',
  });

  const { mutate: updateFeatureMutate } = useToggleDisableFunctionalFeatures();

  const { mutate: createUserMutate } = useCreateUserInTenant();
  const { mutate: deleteUserMutate } = useDeleteUser();
  const { mutate: inviteUserMutate } = useInviteUser();

  const { mutate: inviteGBAUserMutate } = useInviteGBAUser();
  const { mutate: assignGBAUserMutate } = useAssignGBAUserToTenant();
  const { mutate: unassignGBAUserMutate } = useUnassignGBAUserToTenant();

  const handleTabSelectionChanged = useCallback((key: string) => {
    setSelectedTabIndex(key);
  }, []);

  const handleUpdateFeature = useCallback(
    (
      { isEnabled }: Partial<FunctionalFeature>,
      { functionalFeature: { id } }: FunctionalFeature,
    ) => {
      updateFeatureMutate({
        tenantId,
        isEnabled: !!isEnabled,
        featureId: id,
      });
    },
    [tenantId, updateFeatureMutate],
  );

  const handleCreateUser = useCallback(
    (data: AddUserData) => {
      const formattedData = formatCreateUserData(data);
      createUserMutate({ ...formattedData, tenantId });
    },
    [createUserMutate, tenantId],
  );

  return (
    <Tabs
      defaultActiveKey="0"
      className="custom-tabs"
      activeKey={selectedTabIndex}
      onChange={handleTabSelectionChanged}
      items={[
        {
          key: '0',
          label: 'GBA Users',
          children: (
            <Card>
              <GBAUsersTab
                tenantId={tenantId}
                roles={gbaRolesData?.entities}
                users={tenantGBAUsersData?.entities}
                gbaUsers={gbaUsers?.entities}
                inspectors={inspectorsData?.entities}
                onAssignUser={assignGBAUserMutate}
                onUnassignUser={unassignGBAUserMutate}
                onInviteUser={inviteGBAUserMutate}
              />
            </Card>
          ),
        },
        {
          key: '1',
          label: 'Users',
          children: (
            <Card>
              <UsersTab
                roles={rolesData?.entities}
                users={tenantUsersData?.entities}
                inspectors={inspectorsData?.entities}
                onAddUser={handleCreateUser}
                onDeleteUser={deleteUserMutate}
                onInviteUser={inviteUserMutate}
              />
            </Card>
          ),
        },
        {
          key: '2',
          label: 'Features',
          children: (
            <Card>
              <FeaturesTab
                features={tenantFeaturesData?.entities}
                onFeatureUpdate={handleUpdateFeature}
              />
            </Card>
          ),
        },
      ]}
    />
  );
};

export default MasterDetail;
