import React, { FC } from 'react';
import { currencyFormatted } from '@/utils/helpers';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { Charge } from '@/types';

interface ChargesDataGridProps {
  dataSource: Charge[];
}
export const ChargesDataGrid: FC<ChargesDataGridProps> = ({ dataSource }) => {
  const columns: CustomColumns<Charge> = [
    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'chargeType',
      title: 'Charge Type',
    },
    {
      dataIndex: 'price',
      title: 'Price',
      render: (value) => currencyFormatted(value),
      width: 220,
    },
    {
      dataIndex: 'externalPrice',
      title: 'External Price',
      render: (value) => currencyFormatted(value),
      width: 220,
    },
  ];
  return (
    <CustomTable
      id="gridContainer"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
};
