import React, { FC, useCallback, useState } from 'react';
import { useTranslate } from '@/translations';

import { Button } from 'antd';
import { faAdd, Icon } from '@/ui/components/Icon';
import { useInspectionsVehicleDamagePartsById } from '@/api/Inspections';
import { DamagePartsDataGridSection } from './DamagePartsDataGridSection';
import { DamagePartsProps } from './types';
import { AddDamagePartPopup } from '../AddDamagePartPopup/AddDamagePartPopup';

export const DamageParts: FC<DamagePartsProps> = ({
  damageActionId,
  flatRateCode,
  disabled,
}) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const { data, refetch } =
    useInspectionsVehicleDamagePartsById(damageActionId);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const parts = data?.entities.map((e) => ({
    ...e,
    lineCost: e.price * e.quantity,
  }));

  return (
    <>
      <div className="mb-3 d-flex justify-content-between">
        <h6 className="mb-0">{t('partNumbers')}</h6>
        <Button shape="circle" disabled={disabled} onClick={togglePopup}>
          <Icon icon={faAdd} />
        </Button>
      </div>

      <DamagePartsDataGridSection dataSource={parts} onReload={refetch} />

      <AddDamagePartPopup
        vehicleDamageActionId={damageActionId}
        flatRateCode={flatRateCode}
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        onAdded={refetch}
      />
    </>
  );
};
