import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import {
  UpdateDamageLineFormType,
  UpdateDamageLineBody,
} from '@/types/DamagesLines/DamagesLines';
import { useUpdateInspectionsVehiclesDamagesLines } from '@/api/Inspections';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useLocationsSites } from '@/api/Locations/hooks/useLocations';
import { Inspection } from '@/types';
import { CarDamageAreaCode } from '@/utils/constants';

import { Modal } from 'antd';
import { DamageLineForm } from './DamageLineForm';
import { DamageDataGridDataSource } from '../DamageDataGrid/types';

const DEFAULT_FORM_DATA: UpdateDamageLineFormType = {
  areaCode: '' as CarDamageAreaCode,
  partCode: '',
  damageCauseCode: '',
  sectorIndices: [],
  damageConditionCode: '',
  severityId: '' as unknown as number, // prevent preselect first option in Form.Select
  repairMethodCode: '',
  internalGradingId: '' as unknown as number, // prevent preselect first option in Form.Select
  workCenterCode: '',
  responsibilityCode: '',
  responsibilityShortcutCode: '',
  dealerCode: '',
  note: '',
  mechanicalFailure: '',
};

type Props = {
  isPopupVisible: boolean;
  onTogglePopup: () => void;
  onSubmited: () => void;
  inspection: Inspection;
  damageLine: DamageDataGridDataSource;
};

export const EditDamageLinePopup: FC<Props> = ({
  isPopupVisible,
  onTogglePopup,
  onSubmited,
  inspection,
  damageLine,
}) => {
  const idTokenClaims = useIdTokenClaims();
  const tenantId = Number(idTokenClaims?.appTenantId);
  const barCode = inspection.vin;
  const inspectionId = inspection.id;
  const userName = idTokenClaims?.userDisplayName!;

  const { data: locationsSitesData } = useLocationsSites(tenantId, {
    enabled: !!tenantId,
  });

  const siteId = locationsSitesData?.entities.find(
    ({ siteName }) => siteName === inspection.inspectionLocation,
  )?.siteId!;

  const { t } = useTranslate();
  const { mutateAsync: updateInspectionsVehiclesDamagesLinesMutateAsync } =
    useUpdateInspectionsVehiclesDamagesLines();

  const temp: UpdateDamageLineFormType = {
    areaCode:
      (damageLine.areaCode as CarDamageAreaCode) || DEFAULT_FORM_DATA.areaCode,
    partCode: damageLine.partCode || DEFAULT_FORM_DATA.partCode,
    damageCauseCode:
      damageLine.damageCauseCode || DEFAULT_FORM_DATA.damageCauseCode,
    sectorIndices: damageLine.sectorIndices || DEFAULT_FORM_DATA.sectorIndices,
    damageConditionCode:
      damageLine.damageConditionCode || DEFAULT_FORM_DATA.damageConditionCode,
    severityId: damageLine.severityId || DEFAULT_FORM_DATA.severityId,
    repairMethodCode:
      damageLine.repairActionCode || DEFAULT_FORM_DATA.repairMethodCode,
    internalGradingId:
      damageLine.internalGradingId || DEFAULT_FORM_DATA.internalGradingId,
    workCenterCode:
      damageLine.workCenterCode || DEFAULT_FORM_DATA.workCenterCode,
    responsibilityCode:
      damageLine.responsibilityCode || DEFAULT_FORM_DATA.responsibilityCode,
    responsibilityShortcutCode:
      damageLine.responsibilityShortcutCode ||
      DEFAULT_FORM_DATA.responsibilityShortcutCode,
    dealerCode: damageLine.dealerCode || DEFAULT_FORM_DATA.dealerCode,
    note: damageLine.note || DEFAULT_FORM_DATA.note,
    mechanicalFailure:
      damageLine.mechanicalFailure || DEFAULT_FORM_DATA.mechanicalFailure,
  };

  const updateDamageLine = useCallback(
    async (body: UpdateDamageLineBody) => {
      try {
        const { data } = await updateInspectionsVehiclesDamagesLinesMutateAsync(
          body,
        );

        return data;
      } catch (error) {
        console.log('updateDamageLine: ', { error });
        return null;
      }
    },
    [updateInspectionsVehiclesDamagesLinesMutateAsync],
  );

  const defaultSubmit = useCallback(
    async ({ sectorIndices, ...data }: UpdateDamageLineFormType) => {
      const body: UpdateDamageLineBody = {
        ...data,
        sectorIndices: sectorIndices.filter(Boolean), // clear 0 values
        tenantId,
        userName,
        damageLineId: damageLine.id,
        inspectionId,
        siteId,
      };

      await updateDamageLine(body);
    },
    [tenantId, userName, inspectionId, siteId, damageLine, updateDamageLine],
  );

  const handleTogglePopup = useCallback(() => {
    onTogglePopup();
  }, [onTogglePopup]);

  const handleSubmit = useCallback(
    async (data: UpdateDamageLineFormType) => {
      await defaultSubmit(data);
      onSubmited();
      handleTogglePopup();
    },
    [defaultSubmit, handleTogglePopup, onSubmited],
  );

  return (
    <Modal
      open={isPopupVisible}
      onCancel={handleTogglePopup}
      title={t('editDamage')}
      maskClosable
      footer={null}
    >
      <DamageLineForm
        defaultValue={temp}
        onSubmit={handleSubmit}
        barCode={barCode}
        siteId={siteId}
        showDamageImageFiles={false}
      />
    </Modal>
  );
};
