import styled from 'styled-components';

export const FooterContent = styled.footer`
  position: absolute;
  bottom: 0;
  background: ${({ theme }) => theme.main};
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.footerText};
`;
