import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { routes } from '@/routes';
import { Loader } from '@/ui/components/Loader';
import { Location } from '@/types';
import {
  useLocations,
  useCreateLocation,
  useUpdateLocation,
} from '@/api/Locations';
import { useCountries } from '@/api/Countries';
import { useLocationTypes } from '@/api/LocationTypes';
import { Button, Card, Select } from 'antd';
import { BackButton } from '@/ui/components/BackButton';
import { LocationsDataGrid } from './components/LocationsDataGrid/LocationsDataGrid';
import { formatCreateLocationData, formatUpdateLocationData } from './helpers';

export const AdminLocations: FC = () => {
  const { t } = useTranslate();
  const [locationId, setLocationId] = useState('');
  const navigate = useNavigate();

  const { data: locationsData, isLoading: isLocationsLoading } = useLocations();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateLocation();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateLocation();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const { data: locationTypesData, isLoading: isLocationTypesLoading } =
    useLocationTypes();

  const handleClick = useCallback(() => {
    navigate(routes.root, {
      state: {
        locationId,
      },
    });
  }, [locationId, navigate]);

  const handleCreateLocation = useCallback(
    (data: Location) => {
      const formattedData = formatCreateLocationData(data);
      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateLocation = useCallback(
    (newData: Partial<Location>, oldData: Location) => {
      const formattedData = formatUpdateLocationData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isLocationsLoading ||
    isCreateLoading ||
    isUpdateLoading ||
    isCountriesLoading ||
    isLocationTypesLoading
  )
    return <Loader />;

  return (
    <PageContainer>
      <BackButton
        linkTo={routes.systemAdministration}
        title={t('manageLocations')}
      />

      <div className="d-flex align-items-center gap-3">
        <div className="d-flex align-items-center gap-2">
          <span>{t('location')}:</span>
          <Select
            options={locationsData?.entities.map((o) => ({
              label: o.city,
              value: o.id.toString(),
            }))}
            value={locationId}
            placeholder={t('chooseLocation')}
            onChange={(e) => setLocationId(e)}
            style={{ width: 300 }}
            allowClear
          />
        </div>
        <Button type="primary" onClick={handleClick}>
          {t('go')}
        </Button>
      </div>
      <Card>
        <LocationsDataGrid
          onLocationAdd={handleCreateLocation}
          onLocationChanged={handleUpdateLocation}
          locations={locationsData?.entities}
          countries={countriesData?.entities}
          locationTypes={locationTypesData?.entities}
        />
      </Card>
    </PageContainer>
  );
};
