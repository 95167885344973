import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useInspectionsVehiclesParts,
  useUpdateInspectionsVehicleDamageParts,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { partCodeValidator } from '@/utils/validations';
import { AddDamagePartBody } from '@/types';
import { NumberBox, Form } from '@/ui';
import { useTranslate } from '@/translations';
import { NumberBoxWrapper } from '@/modules/Inspection/components/tabs/Damage/components/CreateFlatRateCodePopup/CreateFlatRateCodePopup.styled';

import { Button, Col, Modal, Row } from 'antd';
import { AddPartPopupProps } from './types';

export const AddDamagePartPopup: FC<AddPartPopupProps> = ({
  vehicleDamageActionId,
  flatRateCode,
  isPopupVisible,
  togglePopup,
  onAdded,
}) => {
  const { id: inspectionId } = useParams();
  const { t } = useTranslate();

  const [partCode, setPartCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<number>(0);

  const {
    refetch: refetchParts,
    isLoading: isLoadingParts,
    isRefetching: isRefetchingParts,
  } = useInspectionsVehiclesParts(partCode);

  const { mutateAsync: mutateAsyncDamageParts } =
    useUpdateInspectionsVehicleDamageParts();

  useEffect(() => {
    if (partCodeValidator(partCode)) {
      refetchParts().then((response) => {
        const partCodeData = response?.data?.entities[0];

        if (!partCodeData) return;

        setDescription(partCodeData.description);
        setCost(partCodeData.retailPrice);
      });
    }
  }, [partCode, refetchParts]);

  const onPartCodeChange = useCallback(
    ({ target: { value } }) => setPartCode(value),
    [],
  );

  const onDescriptionChange = useCallback(
    ({ target: { value } }) => setDescription(value),
    [],
  );

  const onQuantityChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    setQuantity(Number(value));
  };

  const onCostChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    const formattedValue = value
      .slice(1) // remove currency symbol
      .trim() // remove white spaces
      .replaceAll(',', ''); // remove a come before converting to number, otherwise it's NaN

    setCost(Number(formattedValue));
  };

  const handleTogglePopup = useCallback(() => {
    setPartCode('');
    setQuantity(1);
    setDescription('');
    setCost(0);

    togglePopup();
  }, [togglePopup]);

  // eslint-disable-next-line
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const body: AddDamagePartBody = {
      vehicleDamageActionId,
      flatRateCode,
      partNumber: partCode,
      quantity,
      price: cost,
      inspectionId: Number(inspectionId),
    };

    try {
      await mutateAsyncDamageParts(body);
      onAdded();
    } catch (error) {
      console.log('handleSubmit: ', { error });
    } finally {
      handleTogglePopup();
    }
  };

  const isSubmitDisabled =
    isLoadingParts ||
    isRefetchingParts ||
    !flatRateCode ||
    !partCode ||
    Number.isNaN(Number(cost));

  return (
    <Modal open={isPopupVisible} onCancel={handleTogglePopup} footer={null}>
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="frCode">
          <Form.Label column sm="4">
            {t('frCode')}
          </Form.Label>
          <Col sm={16}>
            <Form.Control value={flatRateCode} disabled />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="partCode">
          <Form.Label column sm="4">
            {t('code')}
          </Form.Label>
          <Col sm={16}>
            <Form.Control
              value={partCode}
              onChange={onPartCodeChange}
              placeholder="11 digits, ex: 00003103414"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="description">
          <Form.Label column sm="4">
            {t('description')}
          </Form.Label>
          <Col sm={16}>
            <Form.Control
              as="textarea"
              rows={5}
              value={description}
              onChange={onDescriptionChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="quantity">
          <Form.Label column sm="4">
            {t('quantity')}
          </Form.Label>
          <Col sm={16}>
            <NumberBoxWrapper>
              <Form.Control
                as={NumberBox}
                min={1}
                max={100}
                format="#"
                value={quantity}
                onChange={onQuantityChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="cost">
          <Form.Label column sm="4">
            {t('cost')}
          </Form.Label>
          <Col sm={16}>
            <NumberBoxWrapper>
              <Form.Control
                as={NumberBox}
                min={0}
                max={50_000}
                format={{
                  currency: 'GBP',
                  type: 'currency',
                  precision: 2,
                }}
                value={cost}
                onChange={onCostChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button type="primary" disabled={isSubmitDisabled} htmlType="submit">
            {t('save')}
          </Button>
          <Button onClick={handleTogglePopup}>{t('cancel')}</Button>
        </div>
      </Form>
    </Modal>
  );
};
