import React, { useCallback, useState, useEffect } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { faUserPen } from '@/ui/components/Icon';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';
import {
  useCreateTMSUser,
  useDeleteTMSUser,
  useTMSUsers,
  useUpdateTMSUser,
} from '@/api/Users/hooks/useUsers';
import { Loader } from '@/ui/components/Loader';
import { useLocationsSites } from '@/api/Locations/hooks/useLocations';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { useTenants } from '@/api/Tenants';
import { DevButton } from '@/ui';
import { CreateTMSUserPopup } from '@/modules/ManageTMSUsers/components/CreateTMSUserPopup';
import { LocationsSite } from '@/types/Locations/Location';
import { locations } from '@/api/Locations';
import { TMSUser } from '@/types/Users/TMSUsers';

import { Card } from 'antd';
import { ManageTMSUsersDataGrid } from './components/ManageTMSUsersDataGrid';
import { UpdateTMSUserPopup } from './components/UpdateTMSUserPopup';
import { SitesMap } from './types';

export const ManageTMSUsers = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const [isCreatePopupVisible, setIsCreatePopupVisibility] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);

  const [tenantId, setTenantId] = useState('');
  const [sitesMap, setSitesMap] = useState<null | SitesMap>(null);
  const [editUserData, setEditUserData] = useState<TMSUser>({
    id: 0,
    displayName: '',
    userName: '',
    guid: '',
    tenantId: 0,
    inspectorId: 0,
    dateCreated: '',
    siteIds: [],
  });

  const handleToggleCreatePopup = useCallback(() => {
    setIsCreatePopupVisibility(!isCreatePopupVisible);
  }, [isCreatePopupVisible]);

  const handleToggleEditPopup = useCallback(() => {
    setIsEditPopupVisible(!isEditPopupVisible);
  }, [isEditPopupVisible]);

  const handleSetEditUserData = useCallback(
    (data) => {
      setEditUserData(data);
      handleToggleEditPopup();
    },
    [handleToggleEditPopup],
  );

  const { data: tmsUserData, isLoading: tmsUserIsLoading } = useTMSUsers();
  const { data: tenantsData, isLoading: isTenantsLoading } = useTenants();

  const { mutate: createTMSUserMutate } = useCreateTMSUser();
  const { mutate: updateTMSUserMutate } = useUpdateTMSUser();
  const { mutate: deleteTMSUserMutate } = useDeleteTMSUser();
  const { data: locationsSitesData } = useLocationsSites(Number(tenantId), {
    enabled: !!tenantId,
  });

  const { data: inspectorData, isLoading: isInspectorLoading } =
    useInspectionsVehiclesInspectors({
      enabled: !!tmsUserData,
    });

  const loadAllSites = async (tenantIds: number[]) => {
    const resArray = await Promise.all(
      tenantIds.map((id) => locations.getLocationsSites(id)),
    );

    const newSitesMap = resArray.reduce(
      (acc, { data: { entities } }, idx) => ({
        ...acc,
        [tenantIds[idx]]: {
          ...entities.reduce(
            (prev: Record<number, LocationsSite>, site: LocationsSite) => ({
              ...prev,
              [site.siteId]: site,
            }),
            {},
          ),
        },
      }),
      {} as SitesMap,
    );

    setSitesMap(newSitesMap);
  };

  useEffect(() => {
    const tenantIds = tenantsData?.entities.map(({ id }) => id);

    if (!tenantIds?.length) return;

    loadAllSites(tenantIds);
  }, [tenantsData]);

  const handleCreateTMSUser = useCallback(
    (data) => {
      createTMSUserMutate(data);
    },
    [createTMSUserMutate],
  );

  const handleUpdateTMSUser = useCallback(
    (data) => {
      const formattedData = {
        id: data.id,
        inspectorId: {
          isChanged: true,
          value: data.inspectorId,
        },
        tenantId: {
          isChanged: true,
          value: data.tenantId,
        },
        siteIds: {
          isChanged: true,
          value: data.siteIds,
        },
      };

      updateTMSUserMutate(formattedData);
    },
    [updateTMSUserMutate],
  );

  const handleDeleteTMSUser = useCallback(
    (tmsUserId) => {
      deleteTMSUserMutate({ tmsUserId });
    },
    [deleteTMSUserMutate],
  );

  if (tmsUserIsLoading || isInspectorLoading || isTenantsLoading)
    return <Loader />;

  return (
    <PageContainer>
      <Title title={t('manageTMSUsers')} icon={faUserPen} color={accent} />

      <div className="d-flex justify-content-end">
        <DevButton
          stylingMode="outlined"
          icon="add"
          onClick={handleToggleCreatePopup}
        />
      </div>

      <Card>
        <ManageTMSUsersDataGrid
          dataSource={tmsUserData?.entities}
          inspectors={inspectorData?.entities}
          sitesMap={sitesMap}
          onDeleteTMSUser={handleDeleteTMSUser}
          onSetEditUserData={handleSetEditUserData}
        />
      </Card>

      <CreateTMSUserPopup
        sites={locationsSitesData?.entities}
        inspectors={inspectorData?.entities}
        tenants={tenantsData?.entities}
        tenantId={tenantId}
        onSelectTenantId={setTenantId}
        onAddTMSUser={handleCreateTMSUser}
        isCreatePopupVisible={isCreatePopupVisible}
        onToggleCreatePopup={handleToggleCreatePopup}
      />

      <UpdateTMSUserPopup
        inspectors={inspectorData?.entities}
        tenants={tenantsData?.entities}
        onSelectTenantId={setTenantId}
        sitesMap={sitesMap}
        editUserData={editUserData}
        onEditTMSUser={handleUpdateTMSUser}
        isEditPopupVisible={isEditPopupVisible}
        onToggleEditPopup={handleToggleEditPopup}
      />
    </PageContainer>
  );
};
