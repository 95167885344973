import React, { FC, useMemo } from 'react';
import {
  Column,
  DataGrid,
  LoadPanel,
  Paging,
  Pager,
  FilterRow,
  SearchPanel,
  Grouping,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { PTSDataDataGridProps } from '@/modules/PTS/components/types';
import { useSearchParams, Link } from 'react-router-dom';

const PAGE_SIZE = 16;
function renderGridCell(data: undefined) {
  return (
    <Link
      to={{
        pathname: '/passport',
        search: data,
      }}
    >
      View
    </Link>
  );
}

export const PTSDataGrid: FC<PTSDataDataGridProps> = ({ ptsdata }) => {
  const [searchParams] = useSearchParams();
  const searchId = useMemo(() => searchParams.get('id'), [searchParams]);

  return (
    <DataGrid
      id="gridContainer"
      dataSource={ptsdata}
      keyExpr={['statusCode', 'serialNumber']}
      showColumnLines
      showRowLines={false}
      showBorders
      allowColumnReordering
    >
      <LoadPanel enabled />
      <Grouping autoExpandAll={false} />
      <FilterRow visible />
      <SearchPanel visible />

      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, ptsdata?.length)}
      />
      <Column
        caption="Passport History"
        alignment="center"
        cellRender={(e) => renderGridCell(e.data.serialNumber)}
      />

      <Column
        dataField="serialNumber"
        caption="VIN"
        minWidth={200}
        defaultFilterValue={searchId}
      />
      <Column dataField="statusDesc" caption="Status" minWidth={200} />
      <Column dataField="productDesc" />
      <Column dataField="colourDesc" />
      <Column
        dataField="transactionDate"
        caption="Arrival Transaction"
        dataType="datetime"
        format={FORMAT_DATE_WITH_TIME}
      />
      <Column dataField="deliveredLocation" />
      <Column dataField="deliveredPostCode" />
      <Column
        dataField="arrivalDate"
        dataType="datetime"
        minWidth={200}
        width={200}
        format={FORMAT_DATE_WITH_TIME}
      />
    </DataGrid>
  );
};
