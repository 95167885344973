import { Document } from '@/types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export const labelBackgroundColor = (color: string) => ({
  backgroundColor: color,
  visible: true,
});

export const isPagerVisible = (pageSize: number, itemsLength: number = 0) =>
  itemsLength > pageSize;

export const removeExtension = (filename: string) =>
  filename.replace(/\.[^/.]+$/, '');

export const downloadFile = (href: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const filterCustomerPreviewRestrictedItems = (document: Document) =>
  !document.isCustomerPreviewRestricted;

export const currencyFormatted = (
  price?: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (typeof price !== 'number') return null;

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    ...(options || {}),
  }).format(price);
};

export const isValidDate = (dateString: string): boolean =>
  !Number.isNaN(Date.parse(dateString));

export const localDateFormatted = (date: string) =>
  new Date(date).toLocaleDateString('en-GB');

export const localDateTimeFormatted = (
  date: string,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  } as Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat('en-GB', options).format(new Date(date));

export const localTimeFormatted = (
  date: string,
  options = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat('en-GB', options).format(new Date(date));

export const getDifferenceInMonthsFromDate = (from: string, to?: string) => {
  const fromDate = new Date(from);
  const toDate = to ? new Date(to) : new Date();

  const differenceBetweenMonths = toDate.getMonth() - fromDate.getMonth();
  const differenceBetweenYearsInMonths =
    12 * (toDate.getFullYear() - fromDate.getFullYear());

  return differenceBetweenMonths + differenceBetweenYearsInMonths;
};

export const getOptions = <T, K extends keyof T>(
  data: T[] | undefined,
  key: K,
): Array<T[K]> => [...new Set(data?.map((item) => item[key]))];

export const getFormattedLabel = (key: string, searchValue = 'ImageFileUrl') =>
  key
    .replace(searchValue, '')
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const sortDataByDate = <T>(data: T[] | undefined, key: keyof T): T[] =>
  data?.sort(
    (a, b) =>
      new Date(a[key] as unknown as string).getTime() -
      new Date(b[key] as unknown as string).getTime(),
  ) || [];

export const getDataByKey = <T>(
  data: T[] | undefined,
  key: keyof T,
  inspectionStatus: number,
) =>
  data?.find((item) => (item?.[key] as unknown as number) === inspectionStatus);

function checkValueChanged(a: unknown, b: unknown): boolean {
  if (a === null || a === undefined) {
    // Check if `b` is null, undefined, empty string, zero, false, or an empty array.
    const treatAsSame =
      b === null ||
      b === undefined ||
      b === '' ||
      b === 0 ||
      b === false ||
      (Array.isArray(b) && b.length === 0);
    return !treatAsSame;
  }

  if (b === null || b === undefined) {
    const treatAsSame =
      a === null ||
      a === undefined ||
      a === '' ||
      a === 0 ||
      a === false ||
      (Array.isArray(a) && a.length === 0);
    return !treatAsSame;
  }

  // Use deep comparison for arrays and objects.
  if (
    Array.isArray(a) ||
    typeof a === 'object' ||
    Array.isArray(b) ||
    typeof b === 'object'
  ) {
    return JSON.stringify(a) !== JSON.stringify(b);
  }

  // Direct comparison for other types.
  return a !== b;
}

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

export function checkDataChanged<T extends {}>(
  newData: T,
  oldDataStr: string | undefined,
  checkFields: string[],
): boolean {
  if (!oldDataStr || !newData || oldDataStr.length === 0) return false;

  const oldData = JSON.parse(oldDataStr);

  const hasChanged = checkFields
    .map((key) => {
      const oldValue = oldData[key];
      const newValue = (newData as any)[camelize(key)];
      const check = checkValueChanged(oldValue, newValue);
      return check;
    })
    .some((value) => value);

  return hasChanged;
}

export function sectorConverter(sector?: string): string {
  const sectorArray = sector?.split('') || [];
  return sectorArray
    .map((item, index) => (item === '1' ? index + 1 : null))
    .filter((item) => item !== null)
    .join(', ');
}

export const dateConverter = (date: string, format?: string): string =>
  dayjs(date).format(format || 'DD/MM/YYYY');
