import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import { faDownload, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';

import { Link, useParams } from 'react-router-dom';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { TabDocument } from '@/types';
import { DocumentsDataGridProps } from './types';

const linkWrapper = (to: string, value: string | JSX.Element) => (
  <StyledLinkContainer>
    <StyledLink>
      <Link to={to}>{value}</Link>
    </StyledLink>
  </StyledLinkContainer>
);

export const DocumentsDataGrid: FC<DocumentsDataGridProps> = ({
  dataSource,
  vin,
}) => {
  const { t } = useTranslate();
  const { id } = useParams();

  const onRenderActionsCell = useCallback(
    ({ show, fileUrl }: TabDocument) => {
      if (!id) return null;
      const value = <Icon icon={faDownload} />;

      if (show) return linkWrapper(fileUrl(id), value);

      return value;
    },
    [id],
  );

  const onRenderDescriptionLink = ({
    show,
    description,
    link,
  }: TabDocument) => {
    if (!show) return description;

    return (
      <a
        href={link.getHref({ id: id!, vin })}
        target={link.options.target}
        rel={link.options.rel}
      >
        {description}
      </a>
    );
  };

  const columns: CustomColumns<TabDocument> = [
    {
      dataIndex: 'id',
      title: '',
      width: 50,
      align: 'center',
      render: (value, record, index) => `${index + 1}.`,
    },
    {
      dataIndex: 'description',
      title: t('description'),
      render: (value, record) => onRenderDescriptionLink(record),
      filter: true,
    },
    {
      dataIndex: 'id',
      title: '',
      width: 50,
      align: 'center',
      render: (value, record) => onRenderActionsCell(record),
    },
  ];

  return <CustomTable dataSource={dataSource} columns={columns} rowKey="id" />;
};
