import styled from 'styled-components';

export const LicensePlate = styled.div<{
  zoom?: number;
  size?: 'small' | 'large' | 'default';
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  height: ${({ size }) =>
    size === 'small' ? '30px' : size === 'large' ? '50px' : undefined};
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  background: ${({ theme }) => theme.licensePlateBg};
  border: ${({ theme }) => `1px solid ${theme.licensePlateBorder}`};
  border-radius: 8px;
  padding: 5px 8px;
  font-size: ${({ size }) =>
    size === 'small' ? '10px' : size === 'large' ? '30px' : undefined};
  font-weight: bold;
  white-space: nowrap;
  zoom: ${({ zoom }) => zoom || 1};
`;
