import React, { FC, memo } from 'react';
import { Column, DataGrid, Pager, Paging } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { useTranslate } from '@/translations';
import { Loader } from '@/ui/components/Loader';

import { PassportTransactionProps } from './types';

const PAGE_SIZE = 10;

export const PassportTransactions: FC<PassportTransactionProps> = memo(
  ({ passportsTransactionsData, isLoading }) => {
    const { t } = useTranslate();

    if (isLoading) return <Loader />;

    return (
      <DataGrid
        dataSource={passportsTransactionsData?.entities}
        columnHidingEnabled
        showColumnLines
        showRowLines={false}
        showBorders
        allowColumnReordering
        width="100%"
        allowColumnResizing
      >
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(
            PAGE_SIZE,
            passportsTransactionsData?.entities.length,
          )}
        />

        <Column
          dataField="transDescription"
          caption={t('transaction')}
          width={250}
        />
        <Column
          dataField="transDate"
          caption={t('transactionDate')}
          sortOrder="desc"
          dataType="date"
          format={FORMAT_DATE_WITH_TIME}
        />
        <Column dataField="transType" caption={t('transactionCode')} />
        <Column dataField="transVinEndStatus" caption={t('statusCode')} />
        <Column dataField="transUsername" caption={t('userName')} />
        <Column dataField="locationDesc" caption={t('location')} />
      </DataGrid>
    );
  },
);
