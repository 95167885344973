/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

import { ReactComponent as IconCompound } from '@/ui/fonts/compound.svg';
import { ReactComponent as IconTransporter } from '@/ui/fonts/transporter.svg';
import { ReactComponent as IconShortTransporter } from '@/ui/fonts/short_transporter.svg';

export enum Icons {
  compound = 'compound',
  transporter = 'transporter',
  shortTransporter = 'shortTransporter',
}

export type IconProps = {
  icon: IconDefinition | Icons;
  scale?: number;
  color?: string;
  [key: string]: any;
};

export const Icon: FC<IconProps> = ({ icon, scale, color, ...props }) => {
  if (icon === Icons.compound) {
    return <IconCompound width="45px" height="40px" {...props} />;
  }

  if (icon === Icons.transporter) {
    return <IconTransporter width="45px" height="40px" {...props} />;
  }

  if (icon === Icons.shortTransporter) {
    return <IconShortTransporter width="45px" height="40px" {...props} />;
  }

  return (
    <FontAwesomeIcon
      icon={icon as IconProp}
      size={scale ? (`${scale}x` as SizeProp) : undefined}
      color={color}
      {...props}
    />
  );
};
