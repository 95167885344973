import React, { FC } from 'react';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@/ui/theme';
import { ConfigProvider } from 'antd';

export const AppThemeProvider: FC = ({ children }) => {
  const idTokenClaims = useIdTokenClaims();
  const customerTheme = {
    ...defaultTheme,
    ...(idTokenClaims?.tenantColor1
      ? {
          accent: idTokenClaims?.tenantColor1,
        }
      : {}),
    ...(idTokenClaims?.tenantColor2
      ? {
          main: idTokenClaims?.tenantColor2,
        }
      : {}),
  };

  return (
    <ThemeProvider theme={customerTheme}>
      <ConfigProvider
        theme={{
          token: {
            colorBgSpotlight: '#fff',
            colorPrimary: customerTheme.primaryBg,
          },
          components: {
            Descriptions: {
              contentColor: customerTheme.textColor,
              titleColor: customerTheme.lightText,
            },
            Menu: {
              iconSize: 24,
              itemSelectedBg: '#fff',
              itemSelectedColor: customerTheme.accent,
            },
            Select: {
              optionSelectedBg: customerTheme.primaryBg10,
              optionSelectedColor: customerTheme.primaryBg,
            },
            Slider: {
              railSize: 14,
              dotSize: 14,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};
