/* eslint-disable import/no-duplicates */
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType,
  AlignmentType,
  TextRun,
  Footer,
  PageNumber,
} from 'docx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { autoTable, Styles } from 'jspdf-autotable';
import xlsx from 'xlsx-js-style';
import { saveAs } from 'file-saver-es';
import { CustomColumns } from './types';

export type IDownloadTypes = 'csv' | 'pdf' | 'xlsx' | 'docx';

function formatData<T>(columns: CustomColumns<T>, data: T[]) {
  const result: string[][] = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      const temp: string[] = [];
      columns.forEach((column) => {
        const key = column.dataIndex;
        const value = (item as any)[key];
        const exportVal = column.exportRender
          ? column.exportRender(value, item)
          : column.render
          ? column.render(value, item, 0)
          : value;
        temp.push(exportVal);
      });
      result.push(temp);
    });
  }
  return result;
}

function saveJsonToSheet<T>(
  fileName: string,
  columns: CustomColumns<T>,
  rows: string[][],
  type: IDownloadTypes,
) {
  try {
    const workbook = xlsx.utils.book_new();

    const showRows = [
      columns.map((o) => o.titleString || o.title?.toString() || ''),
      ...rows,
    ];

    const worksheet = xlsx.utils.aoa_to_sheet(showRows);
    if (type === 'xlsx') {
      if (rows.length > 0) {
        const width: any[] = [];
        columns.forEach((column, cIndex) => {
          const max_width = rows.reduce(
            (w: number, r: any) =>
              Math.max(w, (r[cIndex] || '').toString().length),
            column.dataIndex.toString().length,
          );
          width.push({ wch: max_width + 2 });
          rows.forEach((_, rIndex) => {
            const ref = xlsx.utils.encode_cell({
              r: rIndex + 1,
              c: cIndex,
            });
            if (worksheet[ref]) {
              worksheet[ref].s = {
                alignment: { horizontal: column.align || 'center' },
              };
            }
          });
        });
        worksheet['!cols'] = width;
      }
      columns.forEach((_, index) => {
        const ref = xlsx.utils.encode_cell({ r: 0, c: index });
        worksheet[ref].s = {
          alignment: { horizontal: 'center' },
          font: { bold: true, color: { rgb: 'ffffff' } },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: '287FBA' },
          },
        };
      });
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const buffer: Buffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'buffer',
      });
      console.log('buffer', buffer.length);
      saveAs(new Blob([buffer]), `${fileName}.xlsx`);
    } else if (type === 'csv') {
      const stream = xlsx.stream.to_csv(worksheet);
      saveAs(stream, `${fileName}.csv`);
    }
  } catch (error) {
    console.error('Save json to sheet', error);
  }
  return false;
}

function addPdfFooters(doc: jsPDF) {
  const pageCount = doc.internal.pages.length - 1;
  for (let i = 1; i <= pageCount; i += 1) {
    doc.setPage(i);
    const y = doc.internal.pageSize.height - 10;
    doc.setFontSize(10);
    doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width / 2, y, {
      align: 'center',
    });
  }
}

function saveJsonToPdf<T>(
  fileName: string,
  columns: CustomColumns<T>,
  rows: string[][],
) {
  try {
    const tooManyColumns = columns.length > 6;
    // eslint-disable-next-line new-cap
    const pdfDoc = new jsPDF({
      orientation: tooManyColumns ? 'landscape' : undefined,
    });

    if (tooManyColumns) pdfDoc.setFontSize(8);
    const columnStyles: any = {};
    columns.forEach((column, index) => {
      const align = column.align || 'center';
      const style: Partial<Styles> = {
        halign: ['start', 'left'].includes(align)
          ? 'left'
          : ['end', 'right'].includes(align)
          ? 'right'
          : AlignmentType.CENTER,
        valign: 'middle',
      };
      columnStyles[index] = style;
    });
    (pdfDoc as jsPDF & { autoTable: autoTable }).autoTable({
      head: [columns.map((o) => o.titleString || o.title?.toString() || '')],
      headStyles: { halign: 'center' },
      body: rows,
      columnStyles,
      rowPageBreak: 'avoid',
      didParseCell({ doc, cell, column }) {
        if (cell === undefined) {
          return;
        }

        const hasCustomWidth = typeof cell.styles.cellWidth === 'number';

        if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
          return;
        }

        let text;

        if (cell.raw instanceof Node) {
          text = cell.raw.innerText;
        } else {
          if (typeof cell.raw === 'object') {
            // not implemented yet
            // when a cell contains other cells (colSpan)
            return;
          }
          text = `${cell.raw}`;
        }

        // split cell string by spaces
        const words = text.split(/\s+/);

        // calculate longest word width
        const maxWordUnitWidth = words
          .map((s) => Math.floor(doc.getStringUnitWidth(s) * 100) / 100)
          .reduce((a, b) => Math.max(a, b), 0);
        const maxWordWidth =
          maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor);

        const minWidth = cell.padding('horizontal') + maxWordWidth;

        // update minWidth for cell & column

        if (minWidth > cell.minWidth) {
          cell.minWidth = minWidth;
        }

        if (cell.minWidth > cell.wrappedWidth) {
          cell.wrappedWidth = cell.minWidth;
        }

        if (cell.minWidth > column.minWidth) {
          column.minWidth = cell.minWidth;
        }

        if (column.minWidth > column.wrappedWidth) {
          column.wrappedWidth = column.minWidth;
        }
      },
    });

    addPdfFooters(pdfDoc);
    pdfDoc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error('Save json to pdf', error);
  }
}

function saveJsonToDocx<T>(
  fileName: string,
  columns: CustomColumns<T>,
  rows: string[][],
) {
  try {
    const tableRows: TableRow[] = [];
    // header
    const headerCells: TableCell[] = columns.map(
      (column) =>
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: column.titleString || column.title?.toString() || '',
                  color: 'ffffff',
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: { fill: '287FBA' },
        }),
    );
    tableRows.push(
      new TableRow({
        children: headerCells,
        tableHeader: true,
        height: { value: 500, rule: 'atLeast' },
      }),
    );

    rows.forEach((row) => {
      const valueCells: TableCell[] = row.map((cellValue, cIndex) => {
        const column = columns[cIndex];
        const align = column.align || 'center';
        return new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: cellValue,
                  size: 14,
                }),
              ],
              alignment: ['start', 'left'].includes(align)
                ? AlignmentType.START
                : ['end', 'right'].includes(align)
                ? AlignmentType.END
                : AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          width:
            column.dataIndex === 'img'
              ? { size: '6%', type: WidthType.PERCENTAGE }
              : undefined,
        });
      });
      tableRows.push(
        new TableRow({
          children: valueCells,
          height: { value: 480, rule: 'atLeast' },
        }),
      );
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: { left: 400, right: 400 },
            },
          },
          children: [table],
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ children: ['Page ', PageNumber.CURRENT] }), // Current page
                    new TextRun({ children: [' of ', PageNumber.TOTAL_PAGES] }), // Total pages
                  ],
                  alignment: AlignmentType.END,
                }),
              ],
            }),
          },
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${fileName}.docx`);
    });
    return true;
  } catch (error) {
    console.error('Save json to docx', error);
    return false;
  }
}

export function exportToFile<T>(
  fileName: string,
  data: T[],
  columns: CustomColumns<T>,
  type: IDownloadTypes,
) {
  columns = columns.filter((o) => o.exportable !== false);
  const d = formatData(columns, data);

  if (Array.isArray(d) && d.length > 0) {
    if (['xlsx', 'csv'].includes(type)) {
      saveJsonToSheet(fileName, columns, d, type);
    } else if (type === 'pdf') {
      saveJsonToPdf(fileName, columns, d);
    } else if (type === 'docx') {
      saveJsonToDocx(fileName, columns, d);
    }
  }
}
