import React, { FC, memo, useCallback } from 'react';
import { Button } from '@/ui';
import { useTranslate } from '@/translations';
import { PreviewModalProps } from '@/ui/components/PreviewModal/types';
import { PDFReader } from '@/ui/components/PdfReader';
import { ExcelReader } from '@/ui/components/ExcelReader';
import { downloadFile } from '@/utils/helpers';
import { TitleWrapper } from '@/ui/components/PreviewModal/PreviewModal.styled';
import { NoPreview } from '@/ui/components/PreviewModal/NoPreview';
import { faClose, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import {
  ImageSupportedExtensions,
  XLSXSupportedExtensions,
} from '@/utils/constants';
import { Image, Modal } from 'antd';

const getFileExtension = (fileName: string): string =>
  fileName.split('.').pop() || '';

const getComponentToRenderByExtension = (
  extension: string,
  src: string,
  closeModal: () => void,
) => {
  switch (true) {
    case extension === 'pdf':
      return () => <PDFReader src={src} />;
    case XLSXSupportedExtensions.includes(extension):
      return () => <ExcelReader src={src} />;
    default:
      // if extension is undefined or not handled
      return () => <NoPreview closeModal={closeModal} />;
  }
};

export const PreviewModal: FC<PreviewModalProps> = memo(
  ({ isOpen, closeModal, src, fileName }) => {
    const { t } = useTranslate();

    const extension = getFileExtension(fileName);

    const titleRender = useCallback(
      () => (
        <TitleWrapper>
          <Button onClick={() => downloadFile(src, fileName)}>
            {t('download')}
          </Button>
          <StyledLinkContainer onClick={closeModal}>
            <StyledLink>
              <Icon icon={faClose} scale={2} />
            </StyledLink>
          </StyledLinkContainer>
        </TitleWrapper>
      ),
      [t, src, fileName, closeModal],
    );

    if (ImageSupportedExtensions.includes(extension))
      return (
        <Image
          width={10}
          style={{ display: 'none' }}
          preview={{
            visible: isOpen,
            src,
            onVisibleChange: () => closeModal(),
          }}
        />
      );

    const ComponentToRender = getComponentToRenderByExtension(
      extension,
      src,
      closeModal,
    );

    return (
      <Modal
        open={isOpen}
        onCancel={closeModal}
        title={titleRender}
        centered
        footer={null}
      >
        <ComponentToRender />
      </Modal>
    );
  },
);
