import React, { FC, useState } from 'react';
import { Button, DatePicker, Select } from 'antd';
import { useTranslate } from '@/translations';
import { FORMAT_DATE_DAYJS } from '@/utils/constants';
import { VesselFiltersInitData, VesselFiltersValues } from '../../types';
import { formatDate } from '../../utils';

type VesselFiltersProps = {
  initData: VesselFiltersInitData;
  onChange: (filters: VesselFiltersValues) => void;
};

export const VesselFilters: FC<VesselFiltersProps> = ({
  initData,
  onChange,
}) => {
  const [locationFrom, setLocationFrom] = useState<string | null>(null);
  const [locationTo, setLocationTo] = useState<string | null>(null);
  const [model, setModel] = useState(null);
  const [despatchDate, setDespatchDate] = useState<string | undefined>(
    undefined,
  );
  const [etaDate, setEtaDate] = useState<string | undefined>(undefined);
  const { t } = useTranslate();

  const handleFilterChange = () => {
    onChange({
      locationFrom,
      locationTo,
      model,
      despatchDate: formatDate(despatchDate),
      etaDate: formatDate(etaDate),
    });
  };

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="d-flex align-items-center gap-2">
        <span>{t('fromLocation')}:</span>
        <Select
          options={initData.locationFromItems.map((item) => ({
            label: item,
            value: item,
          }))}
          value={locationFrom}
          placeholder={t('chooseLocation')}
          onChange={(e) => setLocationFrom(e)}
          allowClear
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <span>{t('toLocation')}:</span>
        <Select
          options={initData.locationToItems.map((item) => ({
            label: item,
            value: item,
          }))}
          value={locationTo}
          placeholder={t('chooseLocation')}
          onChange={(e) => setLocationTo(e)}
          allowClear
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <span>{t('model')}:</span>
        <Select
          options={initData.modelItems.map((item) => ({
            label: item,
            value: item,
          }))}
          value={model}
          placeholder={t('chooseModel')}
          onChange={(e) => setModel(e)}
          allowClear
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <span>{t('despatchDate')}:</span>
        <DatePicker
          value={despatchDate}
          placeholder={t('chooseDate')}
          format={{ format: FORMAT_DATE_DAYJS, type: 'mask' }}
          onChange={(date, dateString) => setDespatchDate(dateString as string)}
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <span>{t('eta')}:</span>
        <DatePicker
          value={etaDate}
          placeholder={t('chooseDate')}
          format={{ format: FORMAT_DATE_DAYJS, type: 'mask' }}
          onChange={(date, dateString) => setEtaDate(dateString as string)}
        />
      </div>
      <Button type="primary" onClick={handleFilterChange}>
        {t('go')}
      </Button>
    </div>
  );
};
