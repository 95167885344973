import React, { FC, useEffect } from 'react';
import { useTranslate } from '@/translations';
import { MainContent, PageContent } from './Page.styled';

export const Page: FC = ({ children }) => {
  const { t } = useTranslate();

  useEffect(() => {
    document.title = t('title');
  }, [t]);

  return <PageContent>{children}</PageContent>;
};

export const Main: FC = ({ children }) => (
  <MainContent id="scrollContainer">{children}</MainContent>
);
