import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { faAngleLeft, Icon } from '../Icon';

export const BackButton: FC<{
  linkTo?: string;
  title: string;
}> = ({ linkTo, title }) => {
  const navigate = useNavigate();

  return (
    <Button
      color="default"
      variant="link"
      onClick={() => {
        if (linkTo) {
          navigate(linkTo);
        } else {
          window.history.back();
        }
      }}
      style={{ width: 'max-content' }}
    >
      <Icon icon={faAngleLeft} />
      {title}
    </Button>
  );
};
