import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';

import { Row } from 'antd';
import { Gallery } from 'react-photoswipe-gallery';
import { TireCard } from './components';
import { splitTireData } from './components/helpers';
import { InspectionPageTab } from '../types';

export const WheelsAndTyres: FC<InspectionPageTab> = ({ inspection }) => {
  const { frontLeft, frontRight, rearLeft, rearRight, spareTyre } =
    splitTireData(inspection);

  return (
    <PageContainer>
      <Gallery>
        <Row gutter={16}>
          <TireCard tireData={frontLeft} />
          <TireCard tireData={frontRight} />
          <TireCard tireData={rearLeft} />
          <TireCard tireData={rearRight} />
          <TireCard tireData={spareTyre} />
        </Row>
      </Gallery>
    </PageContainer>
  );
};
