import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faKey, faUser, Icon } from '@/ui/components/Icon';
import { useGBAUsers } from '@/api/Users';
import { useIdTokenClaims } from '@/hooks';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsVehiclesInspector } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { Button, Descriptions } from 'antd';

export const ManageUserProfile: FC = () => {
  const { t } = useTranslate();

  const { accent } = useTheme();
  const idTokenClaims = useIdTokenClaims();

  const { data, isLoading } = useGBAUsers();

  const user = data?.entities.find(
    (signInUser) => signInUser.email === idTokenClaims?.signInName,
  );

  const { data: inspectorData, isLoading: isInspectorLoading } =
    useInspectionsVehiclesInspector(Number(user?.inspectorId), {
      enabled: !!user?.inspectorId,
    });
  const inspector = inspectorData?.entities?.[0];

  const handleResetPasswordClick = () => {};

  if (isLoading || isInspectorLoading || !user) return <Loader />;

  return (
    <PageContainer>
      <Title title={t('userProfile')} icon={faUser} color={accent} />

      <Descriptions
        column={1}
        bordered
        style={{ maxWidth: 800 }}
        items={[
          { key: '1', label: t('username'), children: user?.displayName ?? '' },
          { key: '1', label: t('role'), children: user?.role.name ?? '' },
          {
            key: '1',
            label: t('inspectorAssigned'),
            children: `${inspector?.inspectorForenames ?? ''} ${
              inspector?.inspectorSurname ?? ''
            }`,
          },
          { key: '1', label: t('email'), children: user?.email ?? '' },
        ]}
      />
      <div>
        <Button type="primary" size="large" onClick={handleResetPasswordClick}>
          <Icon icon={faKey} /> Reset Password
        </Button>
      </div>
    </PageContainer>
  );
};
