import { useTranslate } from '@/translations';
import { AppSearch } from '@/types';
import { StorageKeys } from '@/utils/constants';
import {
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Space,
  Tag,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { useTheme } from 'styled-components';
import { faAdd, faClose, faEdit, faEllipsis, faTrash, Icon } from '../Icon';

const { confirm: confirmModal } = Modal;

export interface QuickViewColumns<T> {
  key: keyof T;
  label: string;
  filterOptions?: ColumnFilterItem[];
}

interface QuickViewPanelProps<T> {
  columns: QuickViewColumns<T>[];
  appSearchStorage: StorageKeys;
  defaultAppSearch?: AppSearch<T>;
  appSearch: AppSearch<T>;
  changeAppSearch: (query: Partial<AppSearch<T>>) => void;
  quickViewFormat?: (query: AppSearch<T>) => void;
}
export function QuickViewPanel<T>({
  columns,
  appSearchStorage,
  defaultAppSearch,
  appSearch,
  changeAppSearch,
  quickViewFormat,
}: QuickViewPanelProps<T>) {
  const [quickViews, setQuickViews] = useState<string[]>([]);
  const [showSaveQuickView, setShowSaveQuickView] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslate();
  const { lightGreen } = useTheme();

  const loadStorageQuickViews = useCallback(() => {
    const prefix = `${appSearchStorage}-`;
    const views = Object.keys(window.localStorage)
      .filter((key) => key.includes(prefix))
      .map((key) => key.replace(prefix, ''));
    setQuickViews(views);
  }, [appSearchStorage]);

  useEffect(() => {
    loadStorageQuickViews();
  }, [loadStorageQuickViews]);

  const onDeleteQuickView = useCallback(
    (key: string) => {
      confirmModal({
        title: 'Confirm',
        content: 'Do you want to delete the quick view?',
        onOk() {
          window.localStorage.removeItem(`${appSearchStorage}-${key}`);
          loadStorageQuickViews();
        },
      });
    },
    [appSearchStorage, loadStorageQuickViews],
  );

  const quickViewMenuItems: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];

    items.push(
      ...quickViews.map((o) => ({
        key: o,
        label: (
          <div className="w-100 d-flex align-items-center gap-2">
            <span className="flex-fill">{o}</span>
            {appSearch.name !== o ? (
              <Icon
                icon={faTrash}
                className="flex-1"
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDeleteQuickView(o);
                }}
              />
            ) : null}
          </div>
        ),
      })),
    );

    const shouldShowManage = !!appSearch.filters || !!appSearch.sortField;
    if (items.length > 0 && shouldShowManage) {
      items.push({ type: 'divider' });
    }
    if (shouldShowManage) {
      items.push({
        key: 'manage-add',
        label: 'Add as new quick view',
        icon: <Icon icon={faAdd} />,
      });
      if (appSearch.name) {
        items.push({
          key: 'manage-update',
          label: 'Update current quick view',
          icon: <Icon icon={faEdit} />,
        });
      }
    }
    return items;
  }, [
    appSearch.filters,
    appSearch.name,
    appSearch.sortField,
    onDeleteQuickView,
    quickViews,
  ]);

  const onMenuClick = useCallback(
    (menuKey: string) => {
      if (menuKey === 'manage-add') {
        form.resetFields();
        setShowSaveQuickView(true);
      } else if (menuKey === 'manage-update') {
        window.localStorage.setItem(
          `${appSearchStorage}-${appSearch.name}`,
          JSON.stringify(appSearch),
        );
        loadStorageQuickViews();
      } else if (menuKey === 'manage-clear') {
        changeAppSearch(
          defaultAppSearch
            ? { ...defaultAppSearch }
            : {
                name: undefined,
                search: undefined,
                sortField: undefined,
                sortOrder: undefined,
                filters: undefined,
              },
        );
      } else {
        const query = JSON.parse(
          window.localStorage.getItem(`${appSearchStorage}-${menuKey}`) || '{}',
        ) as AppSearch<T>;
        quickViewFormat?.(query);
        changeAppSearch(query);
      }
    },
    [
      appSearch,
      appSearchStorage,
      changeAppSearch,
      defaultAppSearch,
      form,
      loadStorageQuickViews,
      quickViewFormat,
    ],
  );

  const onAddQuickView = useCallback(
    (values: any) => {
      if (quickViews.includes(values.name)) {
        form.setFields([{ name: 'name', errors: ['The name already exist.'] }]);
        return;
      }
      appSearch.name = values.name;
      window.localStorage.setItem(
        `${appSearchStorage}-${values.name}`,
        JSON.stringify(appSearch),
      );
      loadStorageQuickViews();
      setShowSaveQuickView(false);
    },
    [appSearch, appSearchStorage, form, loadStorageQuickViews, quickViews],
  );

  const onCloseOneFilter = useCallback(
    (key: keyof T) => {
      const filters = appSearch.filters?.filter((f) => f.key !== key);
      changeAppSearch({ filters });
    },
    [appSearch.filters, changeAppSearch],
  );

  const showFilters = appSearch.filters
    ?.map((filter) => {
      const valueLength = filter.value.length;
      if (valueLength < 1) return null;
      const column = columns.find((o) => o.key === filter.key);
      if (!column) return null;

      const filterOperator =
        valueLength === 1 ? 'Contains' : filter.value[valueLength - 1];
      const filterValues = filter.value.filter(
        (o, idx) => idx < valueLength - 1,
      );
      const showValues = column.filterOptions
        ? filterValues.map(
            (o) =>
              column.filterOptions?.find((f) => f.value.toString() === o)?.text,
          )
        : filterValues;
      return {
        key: filter.key,
        label: column.label,
        opertate: filterOperator,
        value: showValues.join(', '),
      };
    })
    .filter((o) => o)
    .map((o) => o!);

  return (
    <>
      <Space>
        <span>{t('quickView')}:</span>
        <Dropdown
          menu={{
            items: quickViewMenuItems,
            onClick: (e) => onMenuClick(e.key),
          }}
        >
          <Button size="large" style={{ minWidth: 150 }}>
            <div className="w-100 d-flex align-items-center gap-2">
              <span
                className={`text-start flex-fill${
                  appSearch?.name ? '' : ' text-muted'
                }`}
              >
                {appSearch?.name ? appSearch?.name : t('select')}
              </span>
              <Icon icon={faEllipsis} className="flex-1" />
            </div>
          </Button>
        </Dropdown>
        {showFilters.length ? (
          <div className="d-flex align-items-center flex-wrap gap-2">
            {showFilters.map((filter) => (
              <Tag
                key={filter.key as string}
                closeIcon
                onClose={() => onCloseOneFilter(filter.key)}
                className="py-1 m-0"
              >
                <b>{filter.label}</b>
                <span
                  className="mx-1 p-1 rounded-1"
                  style={{ background: lightGreen }}
                >
                  {filter.opertate}
                </span>
                {filter.value}
              </Tag>
            ))}
            {showFilters.length > 1 ? (
              <Button onClick={() => onMenuClick('manage-clear')}>
                <Icon icon={faClose} />
                Clear all filters
              </Button>
            ) : null}
          </div>
        ) : null}
      </Space>

      <Modal
        open={showSaveQuickView}
        title={t('saveQuickView')}
        onCancel={() => setShowSaveQuickView(false)}
        maskClosable
        footer={null}
      >
        <Form form={form} onFinish={onAddQuickView}>
          <Form.Item
            label={t('quickViewName')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button type="primary" htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
