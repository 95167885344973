/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { CustomCardContent } from './CustomCard.styled';

interface CustomCardProps {
  [key: string]: any;
  direction?: 'row' | 'column';
  children: React.ReactNode;
}
export const CustomCard: FC<CustomCardProps> = ({
  direction,
  children,
  ...props
}) => (
  <CustomCardContent direction={direction} {...props}>
    {children}
  </CustomCardContent>
);
