/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { MenuProps, TableProps } from 'antd';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { AppSearch } from '@/types';
import { StorageKeys } from '@/utils/constants';
import { FilterDropdownProps, SortOrder } from 'antd/es/table/interface';
import {
  faAdd,
  faArrowDown,
  faArrowUp,
  faCalendar,
  faDownload,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFilter,
  faSearch,
  Icon,
} from '../Icon';
import { exportToFile } from './exportToFile';
import { CustomColumns, RenderType } from './types';
import { QuickViewColumns, QuickViewPanel } from './QuickViewPanel';
import { EditableRow } from './EditableRow';
import { EditableCell } from './EditableCell';

dayjs.extend(isBetween);

interface CustomTableProps<T>
  extends Omit<TableProps<T>, 'dataSource' | 'columns' | 'title' | 'rowKey'> {
  dataSource: T[];
  columns: CustomColumns<T>;
  rowKey: keyof T;
  exporting?: boolean;
  exportFileName?: string;
  getExportData?: () => Promise<T[]>;
  disabled?: boolean;
  headerActions?: {
    position: 'left' | 'right';
    align?: 'start' | 'end';
    children: React.ReactNode;
  };
  onAdd?: () => void;
  onUpdate?: (oldData: T, newData: Partial<T>) => void;
  quickView?: boolean;
  quickViewFormat?: (query: AppSearch<T>) => void;
  appSearchStorage?: StorageKeys;
  defaultAppSearch?: AppSearch<T>;
  appSearch?: AppSearch<T>;
  onAppSearchChange?: (query: AppSearch<T>) => void;
  search?: string;
  searchPlaceholder?: string;
  onSearch?: (search?: string) => void;
}
export function CustomTable<T>({
  dataSource,
  columns,
  rowKey,
  exporting,
  exportFileName,
  getExportData,
  disabled,
  headerActions,
  onAdd,
  onUpdate,
  quickView,
  quickViewFormat,
  appSearchStorage,
  defaultAppSearch,
  appSearch,
  onAppSearchChange,
  search,
  searchPlaceholder,
  onSearch,
  onChange,
  pagination,
  loading,
  ...restProps
}: CustomTableProps<T>) {
  const [data, setData] = useState<T[]>(dataSource);
  const [uniqueRowKey, setUniqueRowKey] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const { t } = useTranslate();
  const theme = useTheme();

  const dateColumns = columns
    .filter((col) => col.renderType === 'date')
    .map((o) => o.dataIndex as any);
  const columnsWithFilter: QuickViewColumns<T>[] = columns.map((o) => ({
    key: o.dataIndex,
    label: o.titleString || (typeof o.title === 'string' ? o.title : ''),
    filterOptions: o.filterOptions,
  }));
  const showQuickView = !!(appSearch && appSearchStorage && quickView);

  useEffect(() => {
    let shouldUseIdx = false;
    if (!rowKey) {
      shouldUseIdx = true;
    } else {
      const rowKeyData = dataSource.map((o) => JSON.stringify(o[rowKey]));
      const hasDuplicates = rowKeyData.some((item, index) =>
        rowKeyData.some((elem, idx) => elem === item && idx !== index),
      );
      if (hasDuplicates) shouldUseIdx = true;
    }
    setUniqueRowKey(shouldUseIdx ? ('idx' as any) : rowKey);
    setData(
      shouldUseIdx
        ? dataSource.map((o, idx) => ({ ...o, idx }))
        : [...dataSource],
    );
  }, [rowKey, dataSource]);

  useEffect(() => {
    setSearchText(appSearch?.search || search);
  }, [appSearch?.search, search]);

  const filterString = (filter: any, record?: any) =>
    record
      ? record
          .toString()
          .toLowerCase()
          .includes(filter.toString().toLowerCase())
      : false;

  const sortIcon = useCallback(
    (sortOrder: SortOrder) =>
      sortOrder ? (
        <Icon
          icon={sortOrder === 'ascend' ? faArrowUp : faArrowDown}
          style={{ color: theme.accent }}
        />
      ) : null,
    [theme.accent],
  );

  const filterIcon = useCallback(
    (filtered: boolean, renderType?: RenderType) => (
      <Icon
        icon={
          renderType === 'select'
            ? faFilter
            : renderType === 'date'
            ? faCalendar
            : faSearch
        }
        style={{ color: filtered ? theme.accent : undefined }}
      />
    ),
    [theme.accent],
  );

  const customFilter = useCallback(
    (
      {
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }: FilterDropdownProps,
      renderType?: RenderType,
    ) => (
      <Space
        direction="vertical"
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {renderType === 'number' ? (
          <Space>
            <Select
              value={selectedKeys[1] || 'Equal'}
              onChange={(e) => setSelectedKeys([selectedKeys[0], e])}
              style={{ width: 80 }}
            >
              <Select.Option value="Equal">Equal</Select.Option>
              <Select.Option value="Not">Not</Select.Option>
              <Select.Option value="<=">{'<='}</Select.Option>
              <Select.Option value=">=">{'>='}</Select.Option>
            </Select>
            <InputNumber
              className="w-100"
              placeholder={t('search')}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(
                  e !== null ? [e, selectedKeys[1] || 'Equal'] : [],
                )
              }
              onPressEnter={() => confirm()}
            />
          </Space>
        ) : renderType === 'date' ? (
          <DatePicker.RangePicker
            value={selectedKeys[0] as any}
            onChange={(e: any) => setSelectedKeys(e ? [e] : [])}
          />
        ) : renderType === 'select' ? (
          <Space>
            <Select
              value={
                selectedKeys.find((o) => o === 'Equal' || o === 'Not') ||
                'Equal'
              }
              onChange={(e) => {
                const newSelectedKeys = selectedKeys.filter(
                  (o) => o !== 'Equal' && o !== 'Not',
                );
                setSelectedKeys([...newSelectedKeys, e]);
              }}
              style={{ width: 80 }}
            >
              <Select.Option value="Equal">Equal</Select.Option>
              <Select.Option value="Not">Not</Select.Option>
            </Select>
            <Select
              value={selectedKeys.filter((o) => o !== 'Equal' && o !== 'Not')}
              options={filters?.map((o) => ({ label: o.text, value: o.value }))}
              onChange={(e) => {
                const newSelectedKeys = e.filter(
                  (o) => o !== 'Equal' && o !== 'Not',
                );
                const opertate = selectedKeys.find(
                  (o) => o === 'Equal' || o === 'Not',
                );
                console.log(newSelectedKeys, opertate);
                setSelectedKeys([...newSelectedKeys, opertate || 'Equal']);
              }}
              style={{ width: 200 }}
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                ((option?.label as string) ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Space>
        ) : (
          <Space>
            <Select
              value={selectedKeys[1] || 'Contains'}
              onChange={(e) => setSelectedKeys([selectedKeys[0], e])}
              style={{ width: 100 }}
            >
              <Select.Option value="Contains">Contains</Select.Option>
              <Select.Option value="Equal">Equal</Select.Option>
              <Select.Option value="Not">Not</Select.Option>
            </Select>
            <Input
              placeholder={t('search')}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(
                  e.target.value
                    ? [e.target.value, selectedKeys[1] || 'Contains']
                    : [],
                )
              }
              onPressEnter={() => confirm()}
              allowClear
            />
          </Space>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <Button type="primary" onClick={() => confirm()} size="small">
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters?.();
              confirm();
            }}
            size="small"
          >
            Reset
          </Button>
        </div>
      </Space>
    ),
    [t],
  );

  const renderColumns = useMemo(
    () =>
      columns.map((col) => {
        col.sortDirections = ['ascend', 'descend', 'ascend'];
        col.sortIcon = ({ sortOrder }) => sortIcon(sortOrder);
        col.filterIcon = (filtered: boolean) =>
          filterIcon(filtered, col.renderType);
        if (col.filter) {
          col.onFilter = (value: any, record) => {
            if (appSearch) return true;
            const checkValue = record[col.dataIndex];
            const renderValue = col.render
              ? col.render(checkValue, record, 0)?.toString()
              : checkValue;
            return col.renderType === 'select'
              ? value === renderValue
              : col.renderType === 'date'
              ? dayjs(renderValue as string).isBetween(
                  value[0],
                  value[1],
                  'day',
                )
              : filterString(value, renderValue);
          };
          if (col.renderType === 'select') {
            col.filters = col.filterOptions
              ? col.filterOptions
              : typeof col.renderOptions === 'function'
              ? []
              : (col.renderOptions?.map((option) => ({
                  text: option.label,
                  value: option.value,
                })) as any);
          }
          col.filterDropdown = (p) => customFilter(p, col.renderType);
        }
        if (appSearch) {
          col.sortOrder =
            appSearch.sortField === col.dataIndex ? appSearch.sortOrder : null;
          let filteredValue = appSearch.filters?.find(
            (f) => f.key === col.dataIndex,
          )?.value;
          if (filteredValue && col.renderType === 'date') {
            filteredValue = [filteredValue.map((v: any) => dayjs(v))];
          }
          col.filteredValue =
            filteredValue === undefined ? null : filteredValue;
        }
        return !col.editable
          ? col
          : {
              ...col,
              onCell: (record: T) => ({
                record,
                editable: col.editable,
                renderType: col.renderType,
                renderOptions: col.renderOptions,
                rules: col.rules,
                exportRender: col.exportRender,
                dataIndex: col.dataIndex,
                onUpdate,
              }),
            };
      }),
    [appSearch, columns, customFilter, filterIcon, onUpdate, sortIcon],
  );

  const exportMenuItems: MenuProps['items'] = [
    { key: 'xlsx', label: 'XLSX', icon: <Icon icon={faFileExcel} /> },
    { key: 'csv', label: 'CSV', icon: <Icon icon={faFileCsv} /> },
    { key: 'docx', label: 'DOCX', icon: <Icon icon={faFileWord} /> },
    { key: 'pdf', label: 'PDF', icon: <Icon icon={faFilePdf} /> },
  ];

  const onExport = async (type: string) => {
    const list = (await getExportData?.()) || data;
    exportToFile(exportFileName || 'download', list, columns, type as any);
  };

  const changeAppSearch = useCallback(
    (query: Partial<AppSearch<T>>) => {
      const newQuery = { ...appSearch, ...(query as any) };
      // save latest search to local storage
      if (appSearchStorage)
        window.localStorage.setItem(
          `${appSearchStorage}`,
          JSON.stringify(newQuery),
        );
      onAppSearchChange?.(newQuery);
    },
    [appSearch, appSearchStorage, onAppSearchChange],
  );

  const onSearchKeyChange = (e?: string) => {
    onSearch?.(e);
    changeAppSearch?.({ search: e });
  };

  const onTableChange = (
    paginationState: any,
    filters: any,
    sorter: any,
    extra: any,
  ) => {
    const sort = sorter;
    const queryFilters = Object.keys(filters)
      .map((key) => {
        const val = filters[key] ? filters[key]?.[0] : undefined;
        if (!val) return null;

        const filterKey = key as keyof T;
        const filterVal = dateColumns.includes(filterKey)
          ? [dayjs(val[0]).toDate(), dayjs(val[1]).toDate()]
          : filters[key];
        return {
          key: filterKey,
          value: filterVal,
        };
      })
      .filter((f) => f !== null);

    changeAppSearch?.({
      page: paginationState.current,
      pageSize: paginationState.pageSize,
      sortField: sort?.field ? (sort.field as keyof T) : undefined,
      sortOrder: sort?.field ? sort.order : undefined,
      filters: queryFilters as any,
    });

    onChange?.(paginationState, filters, sorter, extra);
  };

  return (
    <Spin spinning={!!loading}>
      {headerActions || onAdd || search || appSearch ? (
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center gap-3">
            {headerActions?.position === 'left' &&
            headerActions?.align === 'start'
              ? headerActions.children
              : null}
            {showQuickView ? (
              <QuickViewPanel
                columns={columnsWithFilter}
                appSearchStorage={appSearchStorage}
                defaultAppSearch={defaultAppSearch}
                appSearch={appSearch}
                changeAppSearch={changeAppSearch}
                quickViewFormat={quickViewFormat}
              />
            ) : null}
            {headerActions?.position === 'left' &&
            headerActions?.align === 'end'
              ? headerActions.children
              : null}
          </div>
          <div className="d-flex align-items-center gap-3">
            {headerActions?.position === 'right' &&
            headerActions?.align === 'start'
              ? headerActions.children
              : null}
            {onAdd ? (
              <Button shape="circle" disabled={disabled} onClick={onAdd}>
                <Icon icon={faAdd} />
              </Button>
            ) : null}
            {exporting ? (
              <Dropdown
                disabled={disabled}
                menu={{
                  items: exportMenuItems,
                  onClick: (info) => onExport(info.key),
                }}
              >
                <Button type="text" shape="circle">
                  <Icon icon={faDownload} style={{ height: 20 }} />
                </Button>
              </Dropdown>
            ) : null}
            {search || appSearch ? (
              <Input.Search
                placeholder={
                  searchPlaceholder || 'Press Enter or click icon to search'
                }
                size="large"
                minLength={3}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={(e) => onSearchKeyChange(e.trim())}
                allowClear
                style={{ minWidth: 300 }}
              />
            ) : null}
            {headerActions?.position === 'right' &&
            headerActions?.align === 'end'
              ? headerActions.children
              : null}
          </div>
        </div>
      ) : null}
      <Table<T>
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={renderColumns as ColumnType<T>[]}
        rowKey={uniqueRowKey}
        onChange={onTableChange}
        pagination={
          pagination !== undefined && pagination !== false
            ? {
                ...pagination,
                showTotal: pagination.showTotal
                  ? pagination.showTotal
                  : (totalItem, range) =>
                      `${range[0]}-${range[1]} of ${totalItem}`,
              }
            : pagination
        }
        {...restProps}
      />
    </Spin>
  );
}
