import React from 'react';
import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { BackButton } from '@/ui/components/BackButton';
import { routes } from '@/routes';
import { Card, Tabs } from 'antd';
import { DamagesDataGrid } from './components/DamagesDataGrid';

const mockData = [
  {
    id: 2,
    description: 'STANDARD',
    inheritedForm: '',
    associatedTemplates: 0,
  },
  {
    id: 3,
    description: 'Standard 26.06.2022',
    inheritedForm: '',
    associatedTemplates: 2,
  },
];

export const Damages = () => {
  const { t } = useTranslate();

  return (
    <PageContainer>
      <BackButton
        linkTo={`${routes.systemAdministration}`}
        title={t('damage')}
      />

      <Card>
        <Tabs
          defaultActiveKey="1"
          className="custom-tabs"
          items={[
            {
              key: '1',
              label: t('repairMatrices'),
              children: <DamagesDataGrid dataSource={mockData} />,
            },
          ]}
        />
      </Card>
    </PageContainer>
  );
};
