import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { b2cPolicies, deployment } from '@/auth/config';
import { Select } from 'antd';

export const TenantSwitcher: FC = () => {
  const { accounts, instance } = useMsal();
  const idTokenClaims = useIdTokenClaims();
  const allTenants = idTokenClaims?.allTenants || [];

  if (allTenants.length < 2) {
    return null;
  }

  const handleTenantChange = (tenantId: string) => {
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signIn.authority,
      scopes: [
        'openid',
        'profile',
        `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.Invite`,
        `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.ReadAll`,
      ],
      account: accounts[0],
      extraQueryParameters: { tenant: tenantId },
    });
  };

  return (
    <Select
      className="tenant-switcher"
      options={allTenants.map((tenant) => {
        const [id, name] = tenant.split(';');
        return {
          label: name,
          value: id,
        };
      })}
      onChange={(key) => handleTenantChange(key)}
      style={{ width: 160, height: 40 }}
      value={idTokenClaims?.appTenantId}
    />
  );
};
