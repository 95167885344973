import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { ChargesDataGrid } from './components/ChargesDataGrid';
import { InspectionPageTab } from '../types';

const mockData = [
  {
    id: 0,
    description: 'Front',
    chargeType: 'Bank',
    price: 0,
    externalPrice: 0,
  },
  {
    id: 1,
    description: 'Back',
    chargeType: 'Cash',
    price: 15,
    externalPrice: 3,
  },
  {
    id: 2,
    description: 'Left',
    chargeType: 'Online',
    price: 5,
    externalPrice: 3,
  },
  {
    id: 3,
    description: 'Right',
    chargeType: 'Credit',
    price: 35,
    externalPrice: 5.45,
  },
];

export const Charges: FC<InspectionPageTab> = () => (
  <PageContainer>
    <ChargesDataGrid dataSource={mockData} />
  </PageContainer>
);
