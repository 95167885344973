export const QUERY_KEYS = {
  company: 'company',
  companyTypes: 'companyTypes',
  countries: 'countries',
  documentManagementDocumentType: 'documentManagementDocumentType',
  documentManagementDocument: 'documentManagementDocument',
  functionalFeatures: 'functionalFeatures',
  gbaUsers: 'gbaUser',
  inspectionsReportsInitial: 'inspectionsReportsInitial',
  inspectionsVehicles: 'inspectionsVehicles',
  inspectionsVehicle: 'inspectionsVehicle',
  inspectionsVehiclesParts: 'inspectionsVehiclesParts',
  inspectionsVehiclesPartsByFrCode: 'inspectionsVehiclesPartsByFrCode',
  inspectionsVehiclesInspectors: 'inspectionsVehiclesInspectors',
  inspectionsVehiclesNotes: 'inspectionsVehiclesNotes',
  inspectionsMechanicalsNotes: 'inspectionsMechanicalsNotes',
  inspectionsMechanicalsWheels: 'inspectionsMechanicalsWheels',
  inspectionsVehiclesMechanicalsNotes: 'inspectionsVehiclesNotes',
  inspectionsVehiclesSummaries: 'inspectionsVehiclesSummaries',
  inspectionsChecklistDynamicOperations:
    'inspectionsChecklistDynamicOperations',
  inspectionsChecklistElectricHybrid: 'inspectionsChecklistElectricHybrid',
  inspectionsChecklistEngineRunning: 'inspectionsChecklistEngineRunning',
  inspectionsChecklistInterior: 'inspectionsChecklistInterior',
  inspectionDamageImage: 'inspectionDamageImage',
  location: 'location',
  locationTypes: 'locationTypes',
  orderSummary: 'orderSummary',
  damages: 'damages',
  damageCodes: 'damageCodes',
  damageConditionCodes: 'damageConditionCodes',
  conditions: 'conditions',
  severityActions: 'severityActions',
  repairActions: 'repairActions',
  damageActions: 'damageActions',
  damageParts: 'damageParts',
  damagePartsById: 'damagePartsById',
  passports: 'passports',
  passportsTransactions: 'passportsTransactions',
  pts: 'pts',
  pipeline: 'pipeline',
  pipelineGraph: 'pipelineGraph',
  pipelineLocations: 'pipelineLocations',
  role: 'role',
  serviceProviders: 'serviceProviders',
  serviceUnits: 'serviceUnits',
  serviceUnitTypes: 'serviceUnitTypes',
  storage: 'storage',
  storageGraph: 'storageGraph',
  tenants: 'tenant',
  shippingPlan: 'shippingPlan',
  transportMovements: 'transportMovements',
  transportPositions: 'transportPositions',
  tnrdetails: 'tnrdetails',
  users: 'user',
  tmsUsers: 'tmsUsers',
  vesselMovements: 'vesselMovements',
  vesselPositions: 'vesselPositions',
  logs: 'logs',
  mechanicalInspection: 'mechanicalInspection',
  mechanicalInspectionAvailable: 'mechanicalInspectionAvailable',
};
