import React, { FC } from 'react';

import { PageContainer } from '@/ui/components/Page';
import { useInspectionsVehicle } from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { useParams } from 'react-router-dom';
import { Document } from '@/types/Documents/Documents';
import { InspectionStatusMap } from '@/utils/constants';

import { DocumentsDataGrid } from './components/DocumentsDataGrid';
import { InspectionPageTab } from '../types';

const getInitialData = (): Document[] => [
  {
    id: 0,
    description: 'Mechanical Report initial',
    fileUrl: (id: string) => `/report/${id}/mechanical`,
    fileName: 'Mechanical Report initial',
    link: {
      getHref: ({ id }) => `/report/${id}/mechanical`, // we don't have MECHANICAL REPORT INITIAL on BMW site
      options: {},
    },
    show: true,
  },
  {
    id: 1,
    description: 'Vehicle Appraisal Report initial',
    fileUrl: (id: string) => `/report/${id}/vehicle`,
    fileName: 'Vehicle Appraisal Report initial',
    link: {
      getHref: ({ id }) => `/report/${id}/vehicle`, // we don't have VEHICLE APPRAISAL INITIAL on BMW site
      options: {},
    },
    show: true,
  },
];

const getApprovedData = ({ show }: { show: boolean }): Document[] => [
  {
    id: 2,
    description: 'Mechanical Report approved',
    fileUrl: (id: string) => `/report/${id}/mechanical/approved`,
    fileName: 'Mechanical Report approved',
    link: {
      getHref: ({ vin }) =>
        `https://bmwreports.azurewebsites.net/mechanicalReport/${vin}`,
      options: {
        /**
         * for external links only,
         * inner links opened in a new tab will not be shown
         * due to sessionStorage authentication policy,
         * user will be logged out
         */
        target: '_blank',
        rel: 'noreferrer',
      },
    },
    show,
  },
  {
    id: 3,
    description: 'Vehicle Appraisal Report approved',
    fileUrl: (id: string) => `/report/${id}/vehicle/approved`,
    fileName: 'Vehicle Appraisal Report approved',
    link: {
      getHref: ({ vin }) =>
        `https://bmwtmsintegration.azurewebsites.net/link/${vin}`,
      options: {
        /**
         * for external links only,
         * inner links opened in a new tab will not be shown
         * due to sessionStorage authentication policy,
         * user will be logged out
         */
        target: '_blank',
        rel: 'noreferrer',
      },
    },
    show,
  },
];

export const Documents: FC<InspectionPageTab> = () => {
  const { id } = useParams();
  const { isLoading, data } = useInspectionsVehicle(Number(id));

  const inspection = data?.entities?.[0];

  if (isLoading || !inspection) return <Loader />;

  const dataSource = [
    ...getInitialData(),
    ...getApprovedData({
      show: [
        InspectionStatusMap.Authorised,
        InspectionStatusMap.Completed,
      ].includes(inspection?.inspectionStatus),
    }),
  ];

  return (
    <PageContainer>
      <DocumentsDataGrid dataSource={dataSource} vin={inspection?.vin} />
    </PageContainer>
  );
};
