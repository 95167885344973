import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
  Sorting,
  RowDragging,
} from '@/ui';
import React, { FC, useCallback } from 'react';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { ChecklistDataGridProps, ReorderRows } from './types';

const PAGE_SIZE = 16;

export const ChecklistDataGrid: FC<ChecklistDataGridProps> = ({
  questions,
  onAddQuestion,
  onEditQuestion,
  onDeleteQuestion,
}) => {
  const { t } = useTranslate();

  // eslint-disable-next-line no-console
  const handleAddQuestion = useCallback(
    (data) => onAddQuestion(data),
    [onAddQuestion],
  );
  const handleEditQuestion = useCallback(
    // eslint-disable-next-line no-console
    ({ oldData, newDate }) => onEditQuestion(oldData, newDate),
    [onEditQuestion],
  );
  // eslint-disable-next-line no-console
  const handleDeleteQuestion = useCallback(
    (data) => onDeleteQuestion(data),
    [onDeleteQuestion],
  );

  const handleReorder = ({ fromIndex, toIndex, itemData }: ReorderRows) => {
    const newData = [...(questions || [])];

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, itemData);
  };

  return (
    <DataGrid
      id="gridContainer"
      keyExpr="id"
      dataSource={questions}
      onRowInserted={handleAddQuestion}
      onRowUpdating={handleEditQuestion}
      onRowRemoving={handleDeleteQuestion}
      columnHidingEnabled
      width="100%"
      showColumnLines
      showRowLines={false}
      showBorders
    >
      <FilterRow visible />
      <Sorting mode="multiple" />
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, questions?.length)}
      />
      <Editing
        mode="form"
        allowAdding
        allowUpdating
        allowDeleting
        confirmDelete={false}
      />
      <RowDragging
        allowReordering
        showDragIcons
        onReorder={handleReorder}
        dropFeedbackMode="push"
      />
      <Column dataField="question" caption={t('question')} />
      <Column
        dataField="attribute"
        caption={t('attributes')}
        allowFiltering={false}
      />
      <Column dataField="fuel" caption={t('fuel')} allowFiltering={false} />
    </DataGrid>
  );
};
