import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '@/routes';
import { Column, DataGrid, Pager, Paging } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { StatusDetailsProps } from './types';

const PAGE_SIZE = 6;

function renderGridCell(data: undefined) {
  return (
    <Link
      to={{
        pathname: routes.passport,
        search: `search=${data}`,
      }}
    >
      View
    </Link>
  );
}

export const PipelineDataGridInner: FC<StatusDetailsProps> = ({
  statusDetailData,
}) => (
  <DataGrid
    dataSource={statusDetailData}
    columnHidingEnabled
    keyExpr="serialNumber"
    width="100%"
    showColumnLines
    showRowLines={false}
    showBorders
    allowColumnReordering
  >
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, statusDetailData?.length)}
    />
    <Column
      caption="Passport History"
      alignment="center"
      cellRender={(e) => renderGridCell(e.data.serialNumber)}
    />

    <Column dataField="serialNumber" minWidth={200} width={200} />
    {/* <Column dataField="productCode" minWidth={130} width={130} /> */}
    <Column dataField="productDesc" width={180} />
    <Column dataField="colourDesc" minWidth={120} width={120} />
    <Column dataField="locationCity" width={120} />
  </DataGrid>
);
