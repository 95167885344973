import { Button, notification, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';

export function AppUpdate() {
  const appUpdateKey = 'app-update';
  const [api, contextHolder] = notification.useNotification();

  const showAppUpdateNotification = useCallback(() => {
    // commit for trigge app update
    console.log('showAppUpdateNotification');
    api.open({
      message: 'A new version is available! ',
      description:
        'The updated content will be applied once all tabs for this page are closed. Click the button below to access the new version.',
      duration: 0, // never close, let the user decide
      closable: false,
      key: appUpdateKey,
      btn: (
        <Space>
          <Button size="small" onClick={() => api.destroy(appUpdateKey)}>
            Not now
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              api.destroy(appUpdateKey);
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }}
          >
            Get new version
          </Button>
        </Space>
      ),
    });
  }, [api]);

  useEffect(() => {
    console.log('check serviceWorker');
    if ('serviceWorker' in navigator && navigator.serviceWorker) {
      console.log('serviceWorker in navigator');
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('serviceWorker in navigator');
        if (event.data && event.data.type === 'SW_INSTALLED') {
          showAppUpdateNotification();
        }
      });
    }
  }, [showAppUpdateNotification]);

  return <div>{contextHolder}</div>;
}
