import React, { FC, useCallback } from 'react';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  RequiredRule,
  FilterRow,
  Sorting,
  ColorBox,
  Form,
  Editing,
  Lookup,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useGBAUserTenants } from '@/api/Tenants';
import { useTranslate } from '@/translations';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { ManageGBAUsersDataGridProps } from './types';

const PAGE_SIZE = 16;

export const MasterDetailView: FC<ManageGBAUsersDataGridProps> = ({
  gbaUserId,
  tenants,
  onAssignTenant,
  onUnassignTenant,
}) => {
  const { t } = useTranslate();

  const { data: gbaUserTenantsData } = useGBAUserTenants(gbaUserId);

  const handleAssignTenant = useCallback(
    ({ data: { id } }) => {
      onAssignTenant({ userId: gbaUserId, tenantId: id });
    },
    [onAssignTenant, gbaUserId],
  );

  const handleUnassignTenant = useCallback(
    (id) => {
      onUnassignTenant({ userId: gbaUserId, tenantId: id });
    },
    [onUnassignTenant, gbaUserId],
  );

  const renderColor1Cell = useCallback(
    ({ data: { color1 } }) => <ColorBox value={color1} readOnly />,
    [],
  );

  const renderColor2Cell = useCallback(
    ({ data: { color2 } }) => <ColorBox value={color2} readOnly />,
    [],
  );

  const renderIconCell = useCallback(
    ({ data: { iconUrl } }) => (
      <img src={iconUrl} alt="tenant icon" style={{ height: '35px' }} />
    ),
    [],
  );

  const renderActiveCell = useCallback(
    ({ data: { isActive } }) => (
      <Form.Check
        className="dx-custom-switch"
        type="switch"
        checked={isActive}
        readOnly
        disabled={!isActive}
      />
    ),
    [],
  );

  const onRenderUnassignCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink onClick={() => handleUnassignTenant(id)}>
          {t('unassign')}
        </StyledLink>
      </StyledLinkContainer>
    ),
    [handleUnassignTenant, t],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      showColumnLines
      showRowLines={false}
      showBorders
      dataSource={gbaUserTenantsData?.entities}
      onRowInserting={handleAssignTenant}
      rowAlternationEnabled
      width="100%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(
          PAGE_SIZE,
          gbaUserTenantsData?.entities?.length,
        )}
      />
      <Editing
        allowAdding
        mode="form"
        texts={{
          saveRowChanges: t('assign'),
        }}
      />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column
        dataField="name"
        caption="Tenant Name"
        formItem={{ visible: false }}
      >
        <RequiredRule />
      </Column>
      <Column
        allowFiltering={false}
        allowSorting={false}
        dataField="iconUrl"
        caption="Icon"
        alignment="center"
        cellRender={renderIconCell}
        width={150}
        formItem={{ visible: false }}
      />
      <Column
        allowFiltering={false}
        dataField="color1"
        caption="Foreground Colour"
        cellRender={renderColor1Cell}
        width={160}
        alignment="center"
        formItem={{ visible: false }}
      />
      <Column
        allowFiltering={false}
        dataField="color2"
        caption="Background Colour"
        cellRender={renderColor2Cell}
        width={160}
        alignment="center"
        formItem={{ visible: false }}
      />
      <Column
        allowEditing
        dataField="isActive"
        caption="Active"
        width={85}
        cellRender={renderActiveCell}
        alignment="center"
        formItem={{ visible: false }}
      />
      <Column
        caption="GBA User"
        width={100}
        allowFiltering={false}
        formItem={{ visible: false }}
        alignment="center"
        cellRender={onRenderUnassignCell}
      />
      <Column dataField="id" caption={t('tenant')} visible={false}>
        <Lookup dataSource={tenants} displayExpr="name" valueExpr="id" />
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};
