import React, { FC } from 'react';
import { AuthorizationStatus } from '@/modules/Inspection/components/tabs/Damage/components/DamageDataGrid/types';
import { useTranslate } from '@/translations';
import { Button, Input, Modal } from 'antd';
import { faClose, faSave, Icon } from '@/ui/components/Icon';
import { ActionsPopupProps } from './types';

export const ActionsPopup: FC<ActionsPopupProps> = ({
  isPopupVisible,
  togglePopup,
  comment,
  setComment,
  handleSaveClick,
  handleCancelClick,
  authorizationStatus,
  backToDefault,
}) => {
  const { t } = useTranslate();

  return (
    <Modal
      open={isPopupVisible}
      onCancel={togglePopup}
      title={t(
        backToDefault
          ? authorizationStatus === AuthorizationStatus.Approved
            ? 'unApproveStatusQuestion'
            : 'unRejectStatusQuestion'
          : authorizationStatus === AuthorizationStatus.Approved
          ? 'approveStatusQuestion'
          : 'rejectStatusQuestion',
      )}
      centered
      footer={null}
      closable={false}
    >
      <Input.TextArea
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t('addNote')}
        required
      />

      <div className="mt-3 d-flex gap-2 justify-content-end">
        <Button
          icon={<Icon icon={faSave} />}
          onClick={handleSaveClick}
          disabled={
            (!comment || !comment.length) &&
            !backToDefault &&
            authorizationStatus === AuthorizationStatus.Rejected
          }
        >
          {t('yes')}
        </Button>
        <Button icon={<Icon icon={faClose} />} onClick={handleCancelClick}>
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
