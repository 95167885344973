import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { getFormattedLabel } from '@/utils/helpers';
import { CustomCard } from '@/ui/components/CustomCard';
import { Descriptions } from 'antd';
import { CardItemProps } from './types';

export const InspectionTabCard: FC<CardItemProps> = ({
  header,
  data,
  noDataMessage,
}) => {
  const { t } = useTranslate();

  const items = Object.entries(data);

  return (
    <>
      <div className="fw-semibold mb-3">{header}</div>
      <CustomCard>
        {items.length === 0 ? (
          noDataMessage
        ) : (
          <Descriptions
            column={1}
            size="small"
            layout="vertical"
            items={items.map(([key, value]) => ({
              key,
              label: getFormattedLabel(key),
              children:
                key === 'signature' && !!value ? (
                  <img
                    className="border w-75"
                    src={value}
                    alt={value}
                    loading="lazy"
                  />
                ) : (
                  value || t('notApplicable')
                ),
            }))}
          />
        )}
      </CustomCard>
    </>
  );
};
