import React, { FC, useCallback } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { routes } from '@/routes';
import { BackButton } from '@/ui/components/BackButton';
import { Card } from 'antd';
import { ChecklistDataGrid } from './components/ChecklistDataGrid';

const fakeData = [
  {
    id: 1,
    question: 'Change scheduler',
    attribute: 'none',
    fuel: 'Hybrid',
  },
  {
    id: 2,
    question: 'Additional information',
    attribute: 'none',
    fuel: 'Diesel',
  },
  {
    id: 3,
    question: 'Cancellation request',
    attribute: 'none',
    fuel: 'Gas',
  },
  {
    id: 4,
    question: 'Test',
    attribute: 'none',
    fuel: 'Electric',
  },
];

export const Checklist: FC = () => {
  const { t } = useTranslate();

  // eslint-disable-next-line no-console
  const handleCreateUser = useCallback((data) => console.log(data), []);
  const handleUpdateUser = useCallback(
    // eslint-disable-next-line no-console
    (newData, oldData) => console.log(newData, oldData),
    [],
  );
  // eslint-disable-next-line no-console
  const handleDeleteUser = useCallback((data) => console.log(data), []);

  return (
    <PageContainer>
      <BackButton
        linkTo={`${routes.systemAdministration}`}
        title={t('functionSettings')}
      />

      <Card>
        <ChecklistDataGrid
          questions={fakeData}
          onAddQuestion={handleCreateUser}
          onEditQuestion={handleUpdateUser}
          onDeleteQuestion={handleDeleteUser}
        />
      </Card>
    </PageContainer>
  );
};
