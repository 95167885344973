import React, { FC, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { Button, message, Tooltip, Upload } from 'antd';
import { faUpload, Icon } from '../Icon';

const { Dragger } = Upload;

interface DragUploadProps {
  uploadButtonText?: string;
  accept?: UploadProps['accept'];
  multiple?: UploadProps['multiple'];
  maxFileSize?: number;
  invalidMaxFileSizeMessage?: string;
  showUploadBtn?: boolean;
  miniMode?: boolean;
  onFileChanged?: (files: UploadFile[]) => void;
  onUpload?: () => Promise<void>;
  onFinished?: () => void;
}

export const DragUpload: FC<DragUploadProps> = ({
  uploadButtonText,
  accept,
  multiple,
  maxFileSize,
  invalidMaxFileSizeMessage,
  showUploadBtn = true,
  miniMode,
  onFileChanged,
  onUpload,
  onFinished,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onChange = (files: UploadFile[]) => {
    if (maxFileSize) {
      const invalidFiles = files.filter(
        (file) => !file.size || file.size > maxFileSize,
      );
      if (invalidFiles.length > 0) {
        const msg = invalidMaxFileSizeMessage || 'File is too large';
        message.error(msg);
        return false;
      }
    }
    setShowUpload(multiple || files.length > 0);
    setFileList(files);
    onFileChanged?.(files);
    return false;
  };

  const onClickUpload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (fileList && fileList.length === 0) return;
    setUploading(true);
    await onUpload?.();
    onChange([]);
    setUploading(false);
    onFinished?.();
  };

  return (
    <div>
      <Dragger
        accept={accept}
        multiple={multiple}
        beforeUpload={() => false}
        onChange={({ fileList: files }) => onChange(files)}
        fileList={fileList}
        className="custom-upload-wrapper"
      >
        <div>
          {miniMode ? (
            <Tooltip title="Click or drag file to this area to upload">
              <div className="ant-upload-drag-icon">
                <Icon icon={faUpload} scale={2} />
              </div>
            </Tooltip>
          ) : (
            <>
              <div className="ant-upload-drag-icon">
                <Icon icon={faUpload} scale={2} />
              </div>
              <div className="ant-upload-text">
                Click or drag file to this area to upload
              </div>
              {multiple ? (
                <div className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </div>
              ) : null}
            </>
          )}
          {showUploadBtn && showUpload ? (
            <Button
              type="primary"
              loading={uploading}
              disabled={uploading}
              onClick={onClickUpload}
              size={miniMode ? 'small' : undefined}
            >
              {uploading ? 'Uploading...' : uploadButtonText || 'Upload'}
            </Button>
          ) : null}
        </div>
      </Dragger>
    </div>
  );
};
