import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { FooterContent } from './Footer.styled';

export const Footer: FC = () => {
  const { t } = useTranslate();
  const currentYear = new Date().getFullYear();

  return <FooterContent>{t('footerCopyright', { currentYear })}</FooterContent>;
};
