import React, { FC, useCallback, useState } from 'react';
import { currencyFormatted, localDateTimeFormatted } from '@/utils/helpers';
import { useTranslate } from '@/translations';

import { AuditActionType, AuditLog } from '@/types';
import { Modal } from 'antd';
import { StyledLink } from '@/ui/globalStyles';
import { faArrowsRotate, Icon } from '@/ui/components/Icon';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { EventViewerDataGridProps } from './types';

const PAGE_SIZE = 6;

export const EventViewerDataGrid: FC<EventViewerDataGridProps> = ({
  loading,
  dataSource,
  reload,
}) => {
  const [showDetails, setShowDetails] = useState<AuditLog>();
  const { t } = useTranslate();

  const renderDataChanges = useCallback((data: AuditLog) => {
    if (!data || !data.eventData) return null;

    const changes = JSON.parse(data.eventData);
    const currencyFields = ['FlatRatePrice'];

    const getShowValue = (val: any, key: string) => {
      if (val === null || val === undefined) return null;
      if (typeof val === 'boolean') return val ? 'Yes' : 'No';
      if (typeof val === 'number')
        return currencyFields.includes(key) ? currencyFormatted(val) : val;
      if (Array.isArray(val)) return null;
      if (typeof val === 'object') return null;
      const checkDate = Date.parse(val);
      if (
        !Number.isNaN(checkDate) &&
        checkDate > 0 &&
        val.split(new RegExp(['\\.', '\\-', '\\/'].join('|'), 'g')).length > 2
      )
        return localDateTimeFormatted(val);
      return val;
    };

    const keys = Object.keys(changes)
      .filter((key) => changes[key]?.Old || changes[key]?.New)
      .sort()
      .map((key) => ({
        key,
        label: key,
        from: changes[key].Old,
        to: changes[key].New,
      }));

    return keys.length > 0
      ? keys
          .filter(({ key }) => changes[key])
          .map(({ key, label, from, to }) => (
            <div key={key}>
              <strong>{label}</strong>:{' '}
              {data.actionType === AuditActionType.Update ? (
                <>
                  changed from <b>{getShowValue(from, key)}</b> to{' '}
                  <b>{getShowValue(to, key)}</b>
                </>
              ) : (
                <span
                  style={{
                    textDecoration:
                      data.actionType === AuditActionType.Delete
                        ? 'line-through'
                        : undefined,
                    color:
                      data.actionType === AuditActionType.UnDelete
                        ? 'green'
                        : undefined,
                  }}
                >
                  {getShowValue(
                    data.actionType === AuditActionType.Delete ? from : to,
                    key,
                  )}
                </span>
              )}
            </div>
          ))
      : 'Cannot show changes for old data';
  }, []);

  const columns: CustomColumns<AuditLog> = [
    {
      dataIndex: 'applicationName',
      title: t('applicationName'),
    },
    {
      dataIndex: 'userName',
      title: t('user'),
    },
    {
      dataIndex: 'actionType',
      title: t('action'),
    },
    {
      dataIndex: 'eventType',
      title: t('dataType'),
    },
    {
      dataIndex: 'eventData',
      title: t('dataChanges'),
      render: (value, record) => (
        <StyledLink onClick={() => setShowDetails(record)}>Details</StyledLink>
      ),
    },
    {
      dataIndex: 'ipAddress',
      title: t('ipAddress'),
    },
    {
      dataIndex: 'latitude',
      title: t('latitude'),
    },
    {
      dataIndex: 'longitude',
      title: t('longitude'),
    },
    {
      dataIndex: 'createAt',
      title: t('dateTime'),
      render: (value) => localDateTimeFormatted(value),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Icon
          icon={faArrowsRotate}
          size="lg"
          className="hoverable-icon"
          onClick={reload}
        />
      </div>
      <CustomTable
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      {showDetails ? (
        <Modal
          title={
            showDetails.actionType === AuditActionType.Create
              ? `A new record was added:`
              : showDetails.actionType === AuditActionType.Update
              ? `A record was updated:`
              : showDetails.actionType === AuditActionType.Delete
              ? `The following record was removed:`
              : showDetails.actionType === AuditActionType.UnDelete
              ? 'The following record has been rolled back:'
              : null
          }
          open
          onCancel={() => setShowDetails(undefined)}
          footer={null}
        >
          <div className="d-flex flex-column gap-2">
            {renderDataChanges(showDetails)}
          </div>
        </Modal>
      ) : null}
    </>
  );
};
