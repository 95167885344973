import React, { useState, FC } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { routes } from '@/routes';
import { useTranslate } from '@/translations';
import { Row, FileUploader, FileLoadPanel, ValueChangedEvent } from '@/ui';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faFileUpload } from '@/ui/components/Icon';
import { useUploadBillOfLading } from '@/api/BillOfLading';
import { MAX_FILE_SIZE } from '@/utils/constants';
import { removeExtension } from '@/utils/helpers';
import { acceptFiles, allowedFileExtensions } from './utils';

export const UploadFile: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { movementId } = useParams();
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const [fileName, setFileName] = useState('');

  const {
    mutate: uploadMutate,
    isSuccess,
    isLoading,
  } = useUploadBillOfLading();

  const uploadFile = () => {
    if (movementId) {
      uploadMutate({
        movementId: Number(movementId),
        data: { billOfLadingFileBase64: file, fileName },
      });
    }
  };

  const onValueChanged = (e: ValueChangedEvent) => {
    const item: File = e.value?.[0]!;

    if (!item) return;
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        setFile(target.result);
        setFileName(removeExtension(item.name));
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  if (isSuccess) {
    return <Navigate replace to={routes.orderAdmin} />;
  }

  return (
    <PageContainer>
      <Title
        title={t('billOfLadingUpload')}
        icon={faFileUpload}
        color={accent}
      />
      <Row>
        <FileLoadPanel visible={isLoading} />
        <FileUploader
          selectButtonText="Select file"
          accept={acceptFiles}
          uploadMode="useButtons"
          allowedFileExtensions={allowedFileExtensions(acceptFiles)}
          uploadFile={uploadFile}
          onValueChanged={onValueChanged}
          invalidMaxFileSizeMessage={t('fileIsTooLarge')}
          maxFileSize={MAX_FILE_SIZE}
        />
      </Row>
    </PageContainer>
  );
};
