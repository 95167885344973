import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { Modal, Switch } from 'antd';
import { CustomCard } from '@/ui/components/CustomCard';
import { InspectionStatusComponentProps } from './types';

export const InspectionStatusComponent: FC<InspectionStatusComponentProps> = ({
  dataSource,
  isPopupVisible,
  onTogglePopup,
  onChangeStatus,
}) => {
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const { t } = useTranslate();

  return (
    <Modal
      open={isPopupVisible}
      onCancel={onTogglePopup}
      title={t('changeInspectionStatus')}
      maskClosable
      footer={null}
      centered
    >
      <div className="d-flex flex-column gap-3">
        {dataSource?.map((status) => (
          <CustomCard
            key={status.id}
            direction="row"
            className="justify-content-between align-items-center"
          >
            <span>{status.text}</span>
            <Switch
              checked={selectedId === status.id}
              onChange={() => {
                if (selectedId) return;
                setSelectedId(status.id);
                setTimeout(async () => {
                  await onChangeStatus(status.id);
                  setSelectedId(null);
                }, 500);
              }}
            />
          </CustomCard>
        ))}
      </div>
    </Modal>
  );
};
