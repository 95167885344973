import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { useTenantFunctionalFeatures } from '@/api/FunctionalFeatures';
import { Header } from '@/ui/components/Header';
import { Main, MainContainer, Page } from '@/ui/components/Page';
import { Footer } from '@/ui/components/Footer';
import { Loader } from '@/ui/components/Loader';
import { useNavigation } from '@/ui/components/Navigation';
import { NoAccessAlert } from '@/ui/components/NoAccessAlert';
import { Home } from '@/modules/Home';
import { Pipeline } from '@/modules/Pipeline';
import { Storage } from '@/modules/Storage';
import { TransportMovement } from '@/modules/TransportMovement';
import { Vessel } from '@/modules/Vessel';
import { Vin } from '@/modules/Vin';
import { paths, routes } from '@/routes';
import {
  Admin,
  AdminCompanies,
  AdminLocations,
  AdminShippingPlan,
  AdminTransportUnits,
  TenantCustomization,
  Checklist,
  Damages,
  Damage,
} from '@/modules/SystemAdministration';
import { Order } from '@/modules/Order';
import { AdminOrder } from '@/modules/AdminOrder';
import { DocumentManagement } from '@/modules/DocumentManagement';
import { UploadFile } from '@/modules/UploadFile';
import { ManageUsers } from '@/modules/ManageUsers';
import { ShippingPlan } from '@/modules/ShippingPlan';
import { TenantManagement } from '@/modules/TenantManagement';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { SignalRConnection } from '@/hooks';
import { ManageGBAUsers } from '@/modules/ManageGBAUsers';
import { Inspections } from '@/modules/Inspections';
import { AppFeatures, AppRoles } from '@/types';
import { hasFeatureAccess, hasRoleAccess } from '@/utils/access';
import { Passport } from '@/modules/Passport';
import { PTS } from '@/modules/PTS';
import { AdminDocumentManagement } from '@/modules/AdminDocumentManagement';
import { Inspection } from '@/modules/Inspection';
import { ManageUserProfile } from '@/modules/ManageUserProfile';
import { ManageTMSUsers } from '@/modules/ManageTMSUsers';
import { MechanicalReport as MechanicalReportInitial } from '@/modules/Inspection/components/tabs/Documents/components/Reports/Initial/MechanicalReport';
import { VehicleAppraisalReport as VehicleAppraisalReportInitial } from '@/modules/Inspection/components/tabs/Documents/components/Reports/Initial/VehicleAppraisalReport';
import { MechanicalReport as MechanicalReportApproved } from '@/modules/Inspection/components/tabs/Documents/components/Reports/Appoved/MechanicalReport';
import { VehicleAppraisalReport as VehicleAppraisalReportApproved } from '@/modules/Inspection/components/tabs/Documents/components/Reports/Appoved/VehicleAppraisalReport';
import { Slider } from '@/ui/components/Slider';
import { AppUpdate } from './components/AppUpdate';

export const App: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const idTokenClaims = useIdTokenClaims();
  const { pages } = useNavigation();

  const userRoles = idTokenClaims?.roles || [];
  const appTenantId = idTokenClaims?.appTenantId;

  const { data: tenantFeaturesData, isLoading } = useTenantFunctionalFeatures(
    Number(appTenantId),
    { enabled: isAuthenticated && !!appTenantId },
  );

  const featuresDisabled = (tenantFeaturesData?.entities || []).reduce(
    (acc, feature) => {
      if (!feature.isEnabled) return [...acc, feature.functionalFeature.code];

      return acc;
    },
    [] as AppFeatures[],
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    // initialize WebSocket connection
    const signalRInstance = SignalRConnection.getInstance();
    signalRInstance.initializeConnection();
  }, [isAuthenticated]);

  const getRouteElement = ({
    Component,
    feature,
    allowedRoles,
  }: {
    Component: React.ElementType;
    feature?: AppFeatures;
    allowedRoles?: AppRoles[];
  }) =>
    hasFeatureAccess(featuresDisabled, feature) &&
    hasRoleAccess(userRoles, allowedRoles) ? (
      <Component />
    ) : (
      <NoAccessAlert />
    );

  if (inProgress !== 'none' || isLoading) {
    return <Loader />;
  }

  const getRootElement = () => {
    if (!isAuthenticated) {
      return <Home />;
    }

    const { appPages } = pages;
    if (!hasFeatureAccess(featuresDisabled, AppFeatures.Pipeline)) {
      return appPages[0] ? (
        <Navigate replace to={appPages[0].to} />
      ) : (
        <NoAccessAlert />
      );
    }

    return <Pipeline />;
  };

  return (
    <>
      <AppUpdate />
      <Header />
      <Page>
        {isAuthenticated ? <Slider /> : null}
        <MainContainer>
          <Main>
            <Routes>
              <Route path={routes.root} element={getRootElement()} />
              {isAuthenticated ? (
                <>
                  <Route
                    path={routes.storage}
                    element={getRouteElement({
                      Component: Storage,
                      feature: AppFeatures.Storage,
                    })}
                  />
                  <Route
                    path={routes.transportSummary}
                    element={getRouteElement({
                      Component: TransportMovement,
                      feature: AppFeatures.TransportMovement,
                    })}
                  />
                  <Route
                    path={routes.vessels}
                    element={getRouteElement({
                      Component: Vessel,
                      feature: AppFeatures.Vessel,
                    })}
                  />
                  <Route
                    path={routes.passport}
                    element={getRouteElement({
                      Component: Passport,
                      feature: AppFeatures.Passport,
                    })}
                  />
                  <Route
                    path={routes.pts}
                    element={getRouteElement({
                      Component: PTS,
                      feature: AppFeatures.StockSheet,
                    })}
                  />
                  <Route
                    path={routes.vin}
                    element={getRouteElement({
                      Component: Vin,
                      feature: AppFeatures.VinTracking,
                    })}
                  />
                  <Route
                    path={routes.order}
                    element={getRouteElement({
                      Component: Order,
                      feature: AppFeatures.Order,
                    })}
                  />
                  <Route
                    path={routes.shippingPlan}
                    element={getRouteElement({
                      Component: ShippingPlan,
                      feature: AppFeatures.ShippingPlan,
                    })}
                  />
                  <Route
                    path={routes.inspections}
                    element={getRouteElement({
                      Component: Inspections,
                      feature: AppFeatures.Inspections,
                    })}
                  />
                  <Route
                    path={routes.orderAdmin}
                    element={getRouteElement({
                      Component: AdminOrder,
                      allowedRoles: [
                        AppRoles.Admin,
                        AppRoles.GbaAdmin,
                        AppRoles.GbaMember,
                        AppRoles.GbaSuperAdmin,
                      ],
                    })}
                  />
                  <Route
                    path={routes.manageUser}
                    element={getRouteElement({
                      Component: ManageUserProfile,
                    })}
                  />
                  <Route
                    path={routes.manageUsers}
                    element={getRouteElement({
                      Component: ManageUsers,
                      allowedRoles: [
                        AppRoles.Admin,
                        AppRoles.GbaAdmin,
                        AppRoles.GbaMember,
                        AppRoles.GbaSuperAdmin,
                      ],
                    })}
                  />
                  <Route
                    path={routes.manageGBAUsers}
                    element={getRouteElement({
                      Component: ManageGBAUsers,
                      allowedRoles: [AppRoles.GbaAdmin, AppRoles.GbaSuperAdmin],
                    })}
                  />
                  <Route
                    path={routes.manageTMSUsers}
                    element={getRouteElement({
                      Component: ManageTMSUsers,
                      allowedRoles: [AppRoles.GbaAdmin, AppRoles.GbaSuperAdmin],
                    })}
                  />
                  <Route
                    path={routes.tenantManagement}
                    element={getRouteElement({
                      Component: TenantManagement,
                      allowedRoles: [AppRoles.GbaAdmin, AppRoles.GbaSuperAdmin],
                    })}
                  />
                  <Route
                    path={routes.documentManagement}
                    element={getRouteElement({
                      Component: DocumentManagement,
                      feature: AppFeatures.DocumentManagement,
                    })}
                  />
                  <Route
                    path={routes.adminDocumentManagement}
                    element={getRouteElement({
                      Component: AdminDocumentManagement,
                      allowedRoles: [
                        AppRoles.Admin,
                        AppRoles.GbaAdmin,
                        AppRoles.GbaMember,
                        AppRoles.GbaSuperAdmin,
                      ],
                    })}
                  />
                  <Route
                    path={routes.systemAdministration}
                    element={getRouteElement({
                      Component: Outlet,
                      allowedRoles: [
                        AppRoles.Admin,
                        AppRoles.GbaAdmin,
                        AppRoles.GbaMember,
                        AppRoles.GbaSuperAdmin,
                      ],
                    })}
                  >
                    <Route index element={<Admin />} />
                    <Route
                      path={paths.locations}
                      element={<AdminLocations />}
                    />
                    <Route
                      path={paths.transportation}
                      element={<AdminTransportUnits />}
                    />
                    <Route
                      path={paths.companies}
                      element={<AdminCompanies />}
                    />
                    <Route
                      path={paths.shippingPlan}
                      element={<AdminShippingPlan />}
                    />
                    <Route
                      path={paths.tenantCustomization}
                      element={<TenantCustomization />}
                    />
                    <Route path={paths.checklist} element={<Checklist />} />
                    <Route path={paths.damages} element={<Damages />} />
                    <Route path={paths.damage} element={<Damage />} />
                  </Route>

                  <Route path={routes.inspection} element={<Inspection />} />
                  <Route
                    path={routes.uploadFile}
                    element={getRouteElement({
                      Component: UploadFile,
                      allowedRoles: [
                        AppRoles.Admin,
                        AppRoles.GbaAdmin,
                        AppRoles.GbaMember,
                        AppRoles.GbaSuperAdmin,
                      ],
                    })}
                  />
                  <Route
                    path={`${routes.report}/${paths.mechanical}`}
                    element={<MechanicalReportInitial />}
                  />
                  <Route
                    path={`${routes.report}/${paths.vehicle}`}
                    element={<VehicleAppraisalReportInitial />}
                  />
                  <Route
                    path={`${routes.report}/${paths.mechanicalApproved}`}
                    element={<MechanicalReportApproved />}
                  />
                  <Route
                    path={`${routes.report}/${paths.vehicleApproved}`}
                    element={<VehicleAppraisalReportApproved />}
                  />
                </>
              ) : (
                <Route
                  path="*"
                  element={<Navigate replace to={routes.root} />}
                />
              )}
            </Routes>
          </Main>
          <Footer />
        </MainContainer>
      </Page>
    </>
  );
};
