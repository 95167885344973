import React, { memo } from 'react';
import { Column, DataGrid, Export, FilterRow, Pager } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { VinMovement } from '@/types';

type VinDataGridProps = {
  dataSource?: VinMovement[];
  onSelectVin: (serialNumber: string) => void;
};

const PAGE_SIZE = 10;

export const VinDataGridComponent = ({
  dataSource,
  onSelectVin,
}: VinDataGridProps) => (
  <DataGrid
    id="gridContainer"
    dataSource={dataSource}
    showColumnLines
    showRowLines={false}
    showBorders
    width="100%"
    columnHidingEnabled
    allowColumnReordering
    hoverStateEnabled
    selection={{
      mode: 'single',
    }}
    paging={{
      pageSize: PAGE_SIZE,
    }}
    onRowClick={(e) => onSelectVin(e.key?.serialNumber as string)}
  >
    <FilterRow visible />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
    />
    <Column
      dataField="serialNumber"
      minWidth={60}
      width={160}
      alignment="left"
    />
    <Column
      dataField="despatchDate"
      minWidth={20}
      width={140}
      alignment="left"
      dataType="date"
      format="dd/MM/yyyy"
    />
    <Column
      caption="ETA Date"
      dataField="etaDate"
      minWidth={20}
      width={120}
      alignment="left"
      dataType="date"
      format="dd/MM/yyyy"
    />
    <Column
      dataField="locationFrom"
      minWidth={20}
      width={130}
      alignment="left"
    />
    <Column dataField="locationTo" minWidth={20} width={130} alignment="left" />
    <Export enabled />
  </DataGrid>
);

export const VinDataGrid = memo(VinDataGridComponent);
