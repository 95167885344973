import React from 'react';
import { Title } from '@/ui/components/Title';
import { Icons } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { useStorage, useStorageGraph } from '@/api/Storage';
import { Loader } from '@/ui/components/Loader';
import { PageContainer } from '@/ui/components/Page';
import { Card, Col, Row } from 'antd';
import { StorageChart } from './components/StorageChart';
import { StorageDataGrid } from './components/StorageDataGrid';

export const Storage = () => {
  const { t } = useTranslate();
  const { data: storageData, isLoading: isStorageLoading } = useStorage();
  const { data: graphData, isLoading: isGraphLoading } = useStorageGraph();

  if (isStorageLoading || isGraphLoading) return <Loader />;

  return (
    <PageContainer>
      <Title title={t('compoundStorage')} icon={Icons.compound} />
      <Row gutter={16}>
        <Col lg={12} md={24}>
          <Card className="h-100">
            <StorageDataGrid storageData={storageData?.entities} />
          </Card>
        </Col>
        <Col lg={12} md={24}>
          <Card className="h-100">
            <StorageChart graphData={graphData?.entities} />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};
