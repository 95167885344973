import {
  Col,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
  Sorting,
  FormItem,
  DataGridForm,
  DataGridPopup,
  Export,
} from '@/ui';
import React, { FC, useCallback, useState } from 'react';
import { getOptions, isPagerVisible } from '@/utils/helpers';
import { Title } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { useTranslate } from '@/translations';
import { DamageDataGridProps } from './types';

const PAGE_SIZE = 16;

export const DamageDataGrid: FC<DamageDataGridProps> = ({ dataSource }) => {
  const { t } = useTranslate();

  const [isEditing, setIsEditing] = useState(true);

  const handleEditingStart = useCallback(() => setIsEditing(true), []);
  const handleInitNewRow = useCallback(() => setIsEditing(false), []);

  const renderTitle = () => (
    <span className="mt-3 d-inline-block">
      <Title>{t(`${isEditing ? 'editItem' : 'addItem'}`)}</Title>
    </span>
  );

  return (
    <Col xxl={8} xl={10} xs={12}>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={dataSource}
        onEditingStart={handleEditingStart}
        onInitNewRow={handleInitNewRow}
        columnHidingEnabled
        width="100%"
        showColumnLines
        showRowLines={false}
        showBorders
        rowAlternationEnabled
      >
        <FilterRow visible />
        <Sorting mode="multiple" />
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Export enabled />
        <Editing
          mode="popup"
          allowAdding
          allowUpdating
          allowDeleting
          confirmDelete={false}
        >
          <DataGridPopup
            showTitle
            titleRender={renderTitle}
            showCloseButton={false}
            position="center"
            minWidth="320"
            maxWidth="520"
            height="auto"
          />

          <DataGridForm>
            <FormItem itemType="group" colSpan={2} colCount={1}>
              <FormItem
                dataField="componentPartType"
                isRequired
                editorType="dxSelectBox"
                editorOptions={{
                  items: getOptions(dataSource, 'componentPartType'),
                }}
                cssClass="d-flex align-item-center"
              />
              <FormItem
                dataField="fault"
                editorType="dxSelectBox"
                isRequired
                editorOptions={{
                  items: getOptions(dataSource, 'fault'),
                }}
              />
              <FormItem
                dataField="subFault"
                editorType="dxSelectBox"
                editorOptions={{
                  items: getOptions(dataSource, 'subFault'),
                }}
              />
              <FormItem
                dataField="size"
                editorType="dxSelectBox"
                editorOptions={{
                  items: getOptions(dataSource, 'size'),
                }}
              />
              <FormItem
                dataField="count"
                editorType="dxSelectBox"
                editorOptions={{
                  items: getOptions(dataSource, 'count'),
                }}
              />
              <FormItem
                dataField="action"
                editorType="dxSelectBox"
                isRequired
                editorOptions={{
                  items: getOptions(dataSource, 'action'),
                }}
              />
              <FormItem
                dataField="severity"
                editorType="dxSelectBox"
                editorOptions={{
                  items: getOptions(dataSource, 'severity'),
                }}
              />
            </FormItem>
          </DataGridForm>
        </Editing>
        <Column dataField="componentPartType" />
        <Column dataField="fault" />
        <Column dataField="subFault" />
        <Column dataField="size" width={120} />
        <Column dataField="count" width={120} />
        <Column dataField="action" />
        <Column dataField="severity" />
      </DataGrid>
    </Col>
  );
};
