import React, { FC, memo } from 'react';
import { useTranslate } from '@/translations';
import { DocumentTypesModalProps } from '@/ui/components/DocumentTypesModal/types';
import {
  useDeleteDocumentType,
  useDocumentTypes,
} from '@/api/DocumentManagement';
import { Loader } from '@/ui/components/Loader';
import { DocumentType } from '@/types';
import { useAddDocumentTypeModal } from '@/ui/components/AddDocumentTypeModal/useAddDocumentTypeModal';

import { Button, Modal } from 'antd';
import { Title } from './DocumentTypesModal.styled';
import { DocumentTypesDataGrid } from './components/DocumentTypesDataGrid';

export const DocumentTypesModal: FC<DocumentTypesModalProps> = memo(
  ({ isOpen, closeModal }) => {
    const documentTypes = useDocumentTypes();
    const { mutate: deleteDocumentTypeMutate } = useDeleteDocumentType();
    const { openAddDocumentTypeModal, RenderAddDocumentTypeModal } =
      useAddDocumentTypeModal();
    const { t } = useTranslate();

    return (
      <>
        <RenderAddDocumentTypeModal />
        <Modal
          open={isOpen}
          onCancel={closeModal}
          width="50vw"
          title={
            <Button type="primary" onClick={openAddDocumentTypeModal}>
              {t('addNew')}
            </Button>
          }
          centered
          footer={null}
        >
          {documentTypes.isLoading ? (
            <Loader />
          ) : (
            <>
              <Title>{t('documentTypes')}</Title>
              <DocumentTypesDataGrid
                documentTypes={documentTypes.data?.entities as DocumentType[]}
                deleteDocumentType={deleteDocumentTypeMutate}
              />
            </>
          )}
        </Modal>
      </>
    );
  },
);
