import styled from 'styled-components';

export const PageContent = styled.div`
  position: fixed;
  top: 96px;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.defaultGrayBgColor};
`;

export const PageContainer = styled.div<{ sticky?: number }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-bottom: ${({ sticky }) => (sticky ? `${sticky - 24}px` : undefined)};
`;

export const MainContainer = styled.div`
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  min-width: 0;
`;

export const MainContent = styled.main`
  height: calc(100% - 48px);
  overflow-y: auto;
`;

export const StickyBottomContainer = styled.div<{
  sticky: number;
  show?: boolean;
}>`
  position: absolute;
  bottom: ${({ sticky, show }) => (show ? 0 : -sticky)}px;
  height: ${({ sticky }) => sticky}px;
  width: 100%;
  background: ${({ theme }) => theme.primaryBg};
  border-radius: 10px 10px 0 0;
  padding: 8px;
  z-index: 1001;
  transition: bottom 1s ease-in-out;
`;
