import React, { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Title } from '@/ui/components/Title';
import { faUsersGear } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { Loader } from '@/ui/components/Loader';
import { PageContainer } from '@/ui/components/Page';
import { Tenant } from '@/types';
import { useCreateTenant, useTenants, useUpdateTenant } from '@/api/Tenants';
import { Card } from 'antd';
import { TenantsDataGrid } from './components/TenantsDataGrid/TenantsDataGrid';
import { formatCreateTenantData, formatUpdateTenantData } from './helpers';

export const TenantManagement: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const { data: tenantsData, isLoading: isTenantsLoading } = useTenants();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateTenant();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateTenant();

  const handleCreateTenant = useCallback(
    (data: Tenant) => {
      const formattedData = formatCreateTenantData(data);
      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateTenant = useCallback(
    (newData: Partial<Tenant>, oldData: Tenant) => {
      const formattedData = formatUpdateTenantData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (isTenantsLoading || isCreateLoading || isUpdateLoading) return <Loader />;

  return (
    <PageContainer>
      <Title title={t('tenantManagement')} icon={faUsersGear} color={accent} />
      <Card>
        <TenantsDataGrid
          onTenantAdd={handleCreateTenant}
          onTenantUpdate={handleUpdateTenant}
          tenants={tenantsData?.entities}
        />
      </Card>
    </PageContainer>
  );
};
