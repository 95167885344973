import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
} from '@/ui';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { faTrash, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer, StyledLink } from '@/ui/globalStyles';
import { useDeleteInspectionsVehicleDamagePart } from '@/api/Inspections/hooks';

import { DamagePartsDataGridProps } from './types';

const PAGE_SIZE = 6;

export const DamagePartsDataGridSection: FC<DamagePartsDataGridProps> = ({
  dataSource,
  onReload,
}) => {
  const { id: inspectionId } = useParams();
  const { t } = useTranslate();
  const { mutateAsync: deleteVehicleDamagePartMutateAsync } =
    useDeleteInspectionsVehicleDamagePart();

  const handleDeleteDamagePartNumber = useCallback(
    async (partNumberId: string) => {
      try {
        await deleteVehicleDamagePartMutateAsync({
          partNumberId,
          inspectionId: Number(inspectionId),
        });
        onReload();
      } catch (error) {
        console.log('handleDeleteDamagePartNumber: ', { error });
      }
    },
    [deleteVehicleDamagePartMutateAsync, inspectionId, onReload],
  );

  const renderActionsCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink onClick={() => handleDeleteDamagePartNumber(id.toString())}>
          <Icon icon={faTrash} />
        </StyledLink>
      </StyledLinkContainer>
    ),
    [handleDeleteDamagePartNumber],
  );

  return (
    <DataGrid
      dataSource={dataSource}
      allowColumnResizing
      allowColumnReordering
      columnAutoWidth
      showColumnLines
      showRowLines={false}
      showBorders
      width="100%"
    >
      <LoadPanel enabled />
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Editing mode="popup" confirmDelete={false} />

      <Column dataField="partNumber" caption={t('code')} />
      <Column dataField="description" caption={t('description')} />
      <Column dataField="quantity" caption={t('quantity')} />
      <Column
        dataField="price"
        caption={t('cost')}
        format={currencyFormatted}
      />
      <Column
        dataField="lineCost"
        caption={t('lineCost')}
        format={currencyFormatted}
      />
      <Column
        dataField="actions"
        minWidth={80}
        width={80}
        alignment="center"
        cellRender={renderActionsCell}
        allowEditing={false}
        allowSorting={false}
        allowFiltering={false}
        allowResizing={false}
      />
    </DataGrid>
  );
};
