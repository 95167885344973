import { useTranslate } from '@/translations';
import { Inspection } from '@/types';
import { currencyFormatted } from '@/utils/helpers';
import React, { FC } from 'react';

interface InspectionPointsTableProps {
  data: Inspection;
}
export const InspectionPointsTable: FC<InspectionPointsTableProps> = ({
  data,
}) => {
  const { t } = useTranslate();

  return (
    <table style={{ width: '100%', lineHeight: 1.1 }}>
      <thead>
        <tr>
          <th aria-label="empty">&nbsp;</th>
          <th className="fw-semibold">{t('cumulative')}</th>
          <th className="fw-semibold">{t('final')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fw-semibold">{t('cost')}</td>
          <td className="text-muted">
            {currencyFormatted(data.cumulativeCost)}
          </td>
          <td className="text-muted">{currencyFormatted(data.finalCost)}</td>
        </tr>
        <tr>
          <td className="fw-semibold">{t('gradePoints')}</td>
          <td className="text-muted">{data.cumulativeGradePoints}</td>
          <td className="text-muted">{data.finalGradePoints}</td>
        </tr>
        <tr>
          <td className="fw-semibold">{t('gradeScore')}</td>
          <td className="text-muted">{data.cumulativeGradeScore}</td>
          <td className="text-muted">{data.finalGradeScore}</td>
        </tr>
      </tbody>
    </table>
  );
};
