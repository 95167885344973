import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faUserTag } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import {
  useCreateGBAUser,
  useDeleteGBAUser,
  useGBAUsers,
  useInviteGBAUser,
  useUpdateGBAUser,
} from '@/api/Users';
import { User } from '@/types';
import { useGBARoles } from '@/api/Roles';
import { useTenants } from '@/api/Tenants';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '@/api/queryKeys';

import { Card } from 'antd';
import { ManageGBAUsersDataGrid } from './components/ManageGBAUsersDataGrid/ManageGBAUsersDataGrid';
import { formatCreateUserData, formatUpdateUserData } from './helpers';

export const ManageGBAUsers = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const { data: tenantsData, isLoading: isTenantsLoading } = useTenants();

  const { data: userData, isLoading: isUserLoading } = useGBAUsers();
  const { data: rolesData, isLoading: isRolesLoading } = useGBARoles();
  const { data: inspectorsData, isLoading: isInspectorsLoading } =
    useInspectionsVehiclesInspectors();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateGBAUser();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateGBAUser();
  const { mutate: deleteMutate, isLoading: isDeleteLoading } =
    useDeleteGBAUser();
  const { mutateAsync: inviteMutateAsync, isLoading: isInviteLoading } =
    useInviteGBAUser();

  const handleCreateUser = useCallback(
    (data: User) => {
      const formattedData = formatCreateUserData(data);
      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateUser = useCallback(
    (newData, oldData) => {
      const formattedData = formatUpdateUserData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  const handleInviteUser = useCallback(
    async (key) => {
      try {
        await inviteMutateAsync(key);
        // refetch users with statuses on invite
        await queryClient.invalidateQueries([QUERY_KEYS.gbaUsers]);
      } catch (error) {
        console.log('handleInviteUser: ', { error });
      }
    },
    [inviteMutateAsync],
  );

  if (
    isUserLoading ||
    isRolesLoading ||
    isInspectorsLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isInviteLoading ||
    isTenantsLoading
  )
    return <Loader />;

  return (
    <PageContainer>
      <Title title={t('manageGBAUsers')} icon={faUserTag} color={accent} />
      <Card>
        <ManageGBAUsersDataGrid
          users={userData?.entities}
          tenants={tenantsData?.entities}
          onAddUser={handleCreateUser}
          onUpdateUser={handleUpdateUser}
          onDeleteUser={deleteMutate}
          onInviteUser={handleInviteUser}
          roles={rolesData?.entities}
          inspectors={inspectorsData?.entities}
        />
      </Card>
    </PageContainer>
  );
};
