import React, { FC, useState, useEffect } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { ImagesLightBoxProps, LightBoxItemProps } from './types';

export const LightBoxItem: FC<LightBoxItemProps> = ({
  image,
  className,
  style,
}) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = image.thumbnailImageUrl;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, [image.thumbnailImageUrl]);

  return dimensions ? (
    <Item<HTMLElement>
      original={image.imageFileUrl}
      thumbnail={image.thumbnailImageUrl}
      alt={image.id?.toString() || ''}
      width={dimensions.width * 5}
      height={dimensions.height * 5}
    >
      {({ ref, open }) => (
        <button
          ref={ref}
          onClick={open}
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
          }}
        >
          <img
            className={`rounded mw-100${className ? ` ${className}` : ''}`}
            style={style}
            src={image.thumbnailImageUrl}
            loading="lazy"
            alt=""
          />
        </button>
      )}
    </Item>
  ) : null;
};

export const ImagesLightBox: FC<ImagesLightBoxProps> = ({
  images,
  className,
  style,
}) => (
  <Gallery>
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gridGap: '12px',
        ...style,
      }}
    >
      {images.map((image) => (
        <div key={image.thumbnailImageUrl}>
          <LightBoxItem image={image} />
          {image.footer?.()}
        </div>
      ))}
    </div>
  </Gallery>
);
