import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faWrench } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { NavigationGrid } from './components/NavigationGrid';

export const Admin: FC = () => {
  const { t } = useTranslate();
  const { defaultLink } = useTheme();

  return (
    <PageContainer>
      <Title
        title={t('systemAdministration')}
        icon={faWrench}
        color={defaultLink}
      />
      <NavigationGrid />
    </PageContainer>
  );
};
