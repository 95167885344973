import React from 'react';
import { PageContainer } from '@/ui/components/Page';
import { useInspectionsLogs } from '@/api/Inspections';
import { Inspection } from '@/types';
import { EventViewerDataGrid } from './components/EventViewerDataGrid';

export const EventViewer = ({ inspection }: { inspection: Inspection }) => {
  const { data, isLoading, refetch } = useInspectionsLogs(inspection.id);

  const dataSource = data?.entities || [];

  return (
    <PageContainer>
      <EventViewerDataGrid
        loading={isLoading}
        dataSource={dataSource}
        reload={refetch}
      />
    </PageContainer>
  );
};
