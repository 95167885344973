import React, { useState } from 'react';
import { useTranslate } from '@/translations';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { PageContainer } from '@/ui/components/Page';
import {
  useShippingPlan,
  useUploadShippingPlan,
  useDeleteShippingPlan,
} from '@/api/ShippingPlan';
import { Loader } from '@/ui/components/Loader';
import { acceptFiles } from '@/modules/UploadFile/utils';
import { MAX_FILE_SIZE } from '@/utils/constants';
import { removeExtension } from '@/utils/helpers';
import { BackButton } from '@/ui/components/BackButton';
import { routes } from '@/routes';
import { Card, UploadFile } from 'antd';
import { DragUpload } from '@/ui/components/DragUpload';
import { AdminShippingPlanDataGrid } from './components/ShippingPlanDataGrid';

export const AdminShippingPlan = () => {
  const { t } = useTranslate();
  const idTokenClaims = useIdTokenClaims();
  const tenant = idTokenClaims?.appTenantId;
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const [fileName, setFileName] = useState('');
  const { data, isLoading } = useShippingPlan(tenant);
  const { mutateAsync: uploadMutate } = useUploadShippingPlan();
  const { mutate: deleteMutate } = useDeleteShippingPlan();

  if (isLoading) return <Loader />;

  const uploadFile = async () => {
    await uploadMutate({
      data: { shippingPlanFileBase64: file, fileName, tenantId: tenant },
    });
  };

  const onFileChanged = (files: UploadFile[]) => {
    if (files.length === 0) {
      setFile(null);
      setFileName('');
      return;
    }

    const item = files[0].originFileObj as File;
    if (!item) return;

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        setFile(target.result);
        setFileName(removeExtension(item.name));
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  return (
    <PageContainer>
      <BackButton
        linkTo={routes.systemAdministration}
        title={t('shippingPlanAdmin')}
      />

      <DragUpload
        accept={acceptFiles}
        uploadButtonText={t('upload')}
        maxFileSize={MAX_FILE_SIZE}
        onFileChanged={onFileChanged}
        onUpload={uploadFile}
      />

      <Card>
        <AdminShippingPlanDataGrid
          dataSource={data?.entities}
          deleteShippingPlan={deleteMutate}
        />
      </Card>
    </PageContainer>
  );
};
