import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import { Button } from 'antd';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { PointInTimeReport } from '@/types';
import { PointInTimeReportsDataGridProps } from './types';

export const PointInTimeReportsDataGrid: FC<
  PointInTimeReportsDataGridProps
> = ({ dataSource }) => {
  const { t } = useTranslate();

  const onRenderCell = useCallback(
    (image) => (
      <a
        href={image}
        download
        target="_blank"
        rel="noreferrer"
        aria-label="View"
      >
        <Button type="primary">{t('viewReport')}</Button>
      </a>
    ),
    [t],
  );

  const columns: CustomColumns<PointInTimeReport> = [
    {
      dataIndex: 'date',
      title: 'Date',
      width: 180,
    },
    {
      dataIndex: 'event',
      title: 'Event',
    },
    {
      dataIndex: 'image',
      width: 220,
      render: onRenderCell,
    },
  ];

  return <CustomTable dataSource={dataSource} columns={columns} rowKey="id" />;
};
