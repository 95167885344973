import React from 'react';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faCarBurst } from '@/ui/components/Icon';

import { Card } from 'antd';
import { InspectionsDataGrid } from './components';

export const Inspections = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  return (
    <PageContainer>
      <Card
        styles={{
          body: { display: 'flex', flexDirection: 'column', gap: '1rem' },
        }}
      >
        <Title title={t('inspectionSearch')} icon={faCarBurst} color={accent} />
        <InspectionsDataGrid />
      </Card>
    </PageContainer>
  );
};
