import styled from 'styled-components';

export const InspectionIdContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.defaultGrayBgColor};
`;
