import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faMapMarkerAlt } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Map } from '@/ui/components/Map';
import { Loader } from '@/ui/components/Loader';
import { useVinMovements, useVinPositions } from '@/api/Vin';
import { Card, Col, Row } from 'antd';
import { VinDataGrid } from './components/VinDataGrid';

export const Vin = () => {
  const [selectedVin, setSelectedVin] = useState<string | undefined>();
  const { t } = useTranslate();
  const { vinLink } = useTheme();
  const { data, isLoading } = useVinMovements();
  const { data: vinPositionsData } = useVinPositions(selectedVin);

  const handleSelectVin = (vin: string) => {
    setSelectedVin(vin);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Title title={t('vinTracking')} icon={faMapMarkerAlt} color={vinLink} />
      <Row gutter={16}>
        <Col lg={12} sm={24}>
          <Card>
            <VinDataGrid
              dataSource={data?.entities}
              onSelectVin={handleSelectVin}
            />
          </Card>
        </Col>
        <Col lg={12} sm={24}>
          <Card className="h-100" styles={{ body: { height: '100%' } }}>
            <Map
              icon={faMapMarkerAlt}
              markers={vinPositionsData?.entities?.map(({ lat, lon }) => ({
                lat: Number(lat),
                lng: Number(lon),
              }))}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};
