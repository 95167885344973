import React from 'react';
import styled from 'styled-components';
import GoogleMapReact, { Coords } from 'google-map-react';
import { Icons, IconDefinition } from '@/ui/components/Icon';
import { MapMarker } from './MapMarker';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
  GOOGLE_MAP_KEY,
} from './constants';

type MapProps = {
  markers?: Coords[];
  icon: Icons | IconDefinition;
  center?: Coords;
  zoom?: number;
};

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Map = ({ markers, icon, center, zoom }: MapProps) => (
  <MapContainer>
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
      defaultCenter={center || DEFAULT_MAP_CENTER}
      defaultZoom={zoom || DEFAULT_MAP_ZOOM}
    >
      {markers?.map(({ lat, lng }) => (
        <MapMarker key={`${lat}_${lng}`} lat={lat} lng={lng} icon={icon} />
      ))}
    </GoogleMapReact>
  </MapContainer>
);
