import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faShip } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Map } from '@/ui/components/Map';
import { Loader } from '@/ui/components/Loader';
import { useVesselMovements, useVesselPositions } from '@/api/Vessel';
import { Card, Col, Row } from 'antd';
import { VesselFilters } from './components/VesselFilters';
import { VesselDataGrid } from './components/VesselDataGrid';
import {
  formatVesselMovements,
  getVesselMovementsFilters,
  filterVesselMovements,
  getFilteredVesselPositions,
} from './utils';
import { VesselFiltersValues } from './types';

export const Vessel = () => {
  const [filterValues, setFilterValues] = useState<VesselFiltersValues>({});
  const [selectedMmsi, setSelectedMmsi] = useState<number[] | null>(null);
  const { t } = useTranslate();
  const { vesselsLink } = useTheme();
  const { data, isLoading } = useVesselMovements();
  const { data: vesselPositionsData } = useVesselPositions();
  const formattedVesselMovements = useMemo(
    () => formatVesselMovements(data?.entities || []),
    [data?.entities],
  );

  const vesselMovementsFiltersData = useMemo(
    () => getVesselMovementsFilters(formattedVesselMovements),
    [formattedVesselMovements],
  );

  const filteredVesselMovements = useMemo(
    () => filterVesselMovements(formattedVesselMovements, filterValues),
    [formattedVesselMovements, filterValues],
  );

  useEffect(() => {
    setSelectedMmsi(filteredVesselMovements.map((mov) => mov.mmsi));
  }, [filteredVesselMovements]);

  const filteredVesselPositions = useMemo(
    () =>
      getFilteredVesselPositions(
        vesselPositionsData?.entities || [],
        selectedMmsi,
        filteredVesselMovements,
      ),
    [vesselPositionsData?.entities, selectedMmsi, filteredVesselMovements],
  );

  const handleFiltersChange = (filters: VesselFiltersValues) => {
    setFilterValues(filters);
  };

  const handleSelectVesselGroup = useCallback((mmsi: number | null) => {
    setSelectedMmsi(mmsi ? [mmsi] : null);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Title title={t('vesselTitle')} icon={faShip} color={vesselsLink} />
      <VesselFilters
        initData={vesselMovementsFiltersData}
        onChange={handleFiltersChange}
      />
      <Row gutter={16}>
        <Col lg={12} sm={24}>
          <Card>
            <VesselDataGrid
              dataSource={filteredVesselMovements}
              onSelectVesselGroup={handleSelectVesselGroup}
            />
          </Card>
        </Col>
        <Col lg={12} sm={24}>
          <Card className="h-100" styles={{ body: { height: '100%' } }}>
            <Map
              icon={faShip}
              markers={filteredVesselPositions.map(({ lat, lon }) => ({
                lat,
                lng: lon,
              }))}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};
