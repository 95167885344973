import React, { FC } from 'react';
import { GbaLogo } from '@/ui/components/Logo/GbaLogo';

import { useTranslate } from '@/translations';
import { Tooltip } from 'antd';
import { Logo4pl } from './Logo.styled';
import Logo4plImg from './4pl-logo.png';

export const Logo: FC = () => {
  const { t } = useTranslate();

  return (
    <Tooltip title={t('productPipeline')}>
      <div>
        <GbaLogo fill="black" />
        <Logo4pl id="logo4pl" src={Logo4plImg} alt="logo" loading="lazy" />
      </div>
    </Tooltip>
  );
};
