import React, { FC, useEffect, useRef } from 'react';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { httpClient, httpClientDefaultHeaders } from '@/api/Core/Core';
import { deployment } from '@/auth/config';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';

export const HttpInterceptor: FC = ({ children }) => {
  const cachedToken = useRef<AuthenticationResult | null>(null);
  const { instance } = useMsal();
  const idTokenClaims = useIdTokenClaims();
  const [tenantId] = idTokenClaims?.appTenantName.split(';') || [];

  useEffect(() => {
    httpClient.interceptors.request.use(
      async (config) => {
        let token = cachedToken.current;
        const account = instance.getAllAccounts()[0];

        const silentRequest = {
          authority: `https://${deployment.b2cTenantName}.b2clogin.com/${deployment.b2cTenantId}/${idTokenClaims?.acr}`,
          scopes: [
            'openid',
            'profile',
            `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.Invite`,
            `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.ReadAll`,
          ],
          account,
          extraQueryParameters: {
            tenant: tenantId,
          },
        };

        if (!token) {
          try {
            token = await instance.acquireTokenSilent(silentRequest);

            cachedToken.current = token;
          } catch (error) {
            /**
             * issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
             * example: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#redirect
             *
             * If the refresh token is expired, MSAL will attempt to retrieve an access tokens silently using a hidden iframe.
             * This will use the sid or username in the account's claims object to retrieve a hint about the user's session.
             * If this hidden iframe call fails, MSAL will pass on an error from the server as an InteractionRequiredAuthError,
             * asking to retrieve an authorization code to retrieve a new set of tokens. You can do this by performing a login
             * or acquireToken API call with the PublicClientApplication object. If the session is still active,
             * the server will send a code without any user prompts. Otherwise, the user will be required to enter their credentials.
             * */
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return instance.acquireTokenRedirect(silentRequest);
            }
          }
        }

        return {
          ...config,
          headers: {
            ...httpClientDefaultHeaders,
            Authorization: `Bearer ${token?.accessToken}`,
          },
        };
      },
      (error) => Promise.reject(error),
    );

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error?.response?.status;
        if ([401, 403].includes(status)) {
          return instance.logout();
        }

        return {};
      },
    );
  }, [idTokenClaims?.acr, instance, tenantId]);

  return children as React.ReactElement;
};
