import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Column,
  DataGrid,
  DevButton,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
  Sorting,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { systemAdministrationRoutes } from '@/routes';
import { Popup } from '@/modules/SystemAdministration/Damages/components/Popup';
import { Modal } from 'antd';
import { DamagesDataGridProps } from './types';

const PAGE_SIZE = 16;

export const DamagesDataGrid: FC<DamagesDataGridProps> = ({ dataSource }) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const onRenderCell = useCallback(
    ({ data }) => {
      const isArchive = data.description === 'STANDARD';

      return (
        <div className="d-flex align-items-center gap-2">
          <DevButton
            text={t(`${isArchive ? 'archive' : 'swap'}`)}
            stylingMode="outlined"
            icon="edit"
          />
          <Link
            to={{
              pathname: `${systemAdministrationRoutes.damage}/${data.id}`,
              search: encodeURI(data.description),
            }}
          >
            <DevButton text={t('edit')} stylingMode="outlined" icon="edit" />
          </Link>
        </div>
      );
    },
    [t],
  );

  return (
    <>
      <div className="my-3 d-flex gap-2">
        <DevButton text={t('add')} stylingMode="outlined" icon="add" />
        <DevButton
          text={t('import')}
          stylingMode="outlined"
          icon="import"
          onClick={togglePopup}
        />
      </div>

      <Modal
        open={isPopupVisible}
        onCancel={togglePopup}
        closable={false}
        centered
        footer={null}
        width={800}
      >
        <Popup onTogglePopup={togglePopup} />
      </Modal>

      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={dataSource}
        columnHidingEnabled
        width="100%"
        showColumnLines
        showRowLines={false}
        showBorders
        rowAlternationEnabled
      >
        <FilterRow visible />
        <Sorting mode="multiple" />
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Column dataField="description" />
        <Column dataField="inheritedForm" />
        <Column dataField="associatedTemplates" alignment="left" />
        <Column cellRender={onRenderCell} width={300} />
      </DataGrid>
    </>
  );
};
