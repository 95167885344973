export const defaultTheme = {
  lightText: '#838383',
  textColor: '#2c2c2c',
  primaryBg: '#041b53',
  primaryBg10: '#041b531a',
  white: '#ffffff',
  black: '#000000',
  lightGray: '#bdc3c7',
  lightGreen: '#d2ffd2',
  lightRed: '#ffd2d2',
  gray: '#666666',
  accent: '#f5871f',
  main: '#001957',
  loginFormBackground: 'rgba(0,0,0,.5)',
  loginFormText: '#ffffff',
  loginBannerInfo: '#ffffff',
  footerText: '#ffffff',
  defaultLink: '#566371',
  storageLink: '#a1c820',
  transportMovementLink: '#41c0cb',
  vesselsLink: '#ffca1b',
  licensePlateBorder: '#e0d100',
  licensePlateBg: ' linear-gradient(to bottom, #f2e209 0%, #f4d430 100%)',
  passport: '#ca2b2b',
  vinLink: '#bd9920',
  shippingPlanLink: '#ca2b2b',
  dataGridPager: '#ddd',
  mainIconColor: '#a1c820',
  additionalIconColor: '#008000',
  primaryChartColor: 'rgb(29, 178, 245)',
  secondaryChartColor: 'rgb(245, 86, 74)',
  mainExcelHeaderColor: 'bedfe6',
  mainExcelBorderColor: 'a1a1a1',

  defaultIconLinkColour: '#ffffff',
  defaultGrayBgColor: '#f7f6f9',
};
