import React, { FC } from 'react';
import { Icon, Icons, IconDefinition, faFileExcel } from '@/ui/components/Icon';
import { Col, Row } from 'antd';
import { AdditionalIcon, MainIcon, TitleText } from './Title.styled';

type TitleProps = {
  title: string;
  icon: IconDefinition | Icons;
  isAdditionIcon?: boolean;
  color?: string;
};

export const Title: FC<TitleProps> = ({
  title,
  icon,
  isAdditionIcon = false,
  color,
}) => (
  <Row gutter={16}>
    <Col lg={10} md={12} sm={20}>
      <TitleText>
        <MainIcon color={color}>
          <Icon icon={icon} />
        </MainIcon>
        {title}
      </TitleText>
    </Col>
    {isAdditionIcon ? (
      <Col lg={14} md={12} sm={4}>
        <AdditionalIcon>
          <Icon icon={faFileExcel} />
        </AdditionalIcon>
      </Col>
    ) : null}
  </Row>
);
