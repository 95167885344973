import React, { FC, useState } from 'react';
import { useTranslate } from '@/translations';
import { formatDate } from '@/modules/Vessel/utils';
import { FORMAT_DATE_DAYJS } from '@/utils/constants';
import { Button, DatePicker, Select } from 'antd';
import { TransportMovementFiltersProps } from './types';

export const TransportMovementFilters: FC<TransportMovementFiltersProps> = ({
  initData,
  onChange,
}) => {
  const { t } = useTranslate();
  const [locationFrom, setLocationFrom] = useState<string | null>(null);
  const [locationTo, setLocationTo] = useState<string | null>(null);
  const [despatchDate, setDespatchDate] = useState<string | undefined>(
    undefined,
  );

  const handleFilterChange = () => {
    onChange({
      locationFrom,
      locationTo,
      despatchDate: formatDate(despatchDate),
    });
  };

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="d-flex align-items-center gap-2">
        <span>{t('fromLocation')}:</span>
        <Select
          options={initData.locationFromItems.map((loc) => ({
            label: loc,
            value: loc,
          }))}
          placeholder={t('chooseLocation')}
          onChange={(key) => setLocationFrom(key)}
          style={{ minWidth: 200 }}
          value={locationFrom}
          allowClear
        />
      </div>

      <div className="d-flex align-items-center gap-2">
        <span>{t('toLocation')}:</span>
        <Select
          options={initData.locationToItems.map((loc) => ({
            label: loc,
            value: loc,
          }))}
          placeholder={t('chooseLocation')}
          onChange={(e) => setLocationTo(e)}
          style={{ minWidth: 200 }}
          value={locationTo}
          allowClear
        />
      </div>

      <div className="d-flex align-items-center gap-2">
        <span>{t('despatchDate')}:</span>
        <DatePicker
          placeholder={t('chooseDate')}
          onChange={(date, dateString) => setDespatchDate(dateString as string)}
          format={{ format: FORMAT_DATE_DAYJS, type: 'mask' }}
          allowClear
        />
      </div>

      <Button type="primary" onClick={handleFilterChange}>
        {t('go')}
      </Button>
    </div>
  );
};
