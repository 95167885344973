import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { Icons } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import {
  usePipelineLocations,
  usePipelineGraph,
  usePipelineStatus,
} from '@/api/Pipelines';
import { PageContainer } from '@/ui/components/Page';
import { Button, Card, Col, Row, Select } from 'antd';
import { PipelineChart } from './components/PipelineChart';
import { PipelineDataGrid } from './components/PipelineDataGrid';
import { filterStatusData, formatStatusData } from './utils';
import { LocationFiltersValues } from './types';

interface PipelineLocation {
  state?: {
    locationId: string;
  };
}

export const Pipeline = () => {
  const { t } = useTranslate();
  const location = useLocation() as PipelineLocation;
  const stateLocationId = location.state?.locationId ?? '';

  const [locationId, setLocationId] = useState(stateLocationId);
  const [filterValues] = useState<LocationFiltersValues>({});
  const [submittedLocationId, setSubmittedLocationId] =
    useState(stateLocationId);
  const [, setSelectedUnitName] = useState<string[] | null>(null);

  const { data: locationsData, isLoading: isLocationsLoading } =
    usePipelineLocations();
  const locationItems = (locationsData?.entities || []).map((loc) => ({
    label: loc.city,
    value: loc.id.toString(),
  }));
  const { data: graphData, isLoading: isGraphLoading } = usePipelineGraph();
  const { data: statusData, isLoading: isStatusLoading } =
    usePipelineStatus(submittedLocationId);

  const formattedStates = useMemo(
    () => formatStatusData(statusData?.statuses || []),
    [statusData?.statuses],
  );

  const filteredStatuses = useMemo(
    () => filterStatusData(formattedStates, filterValues),
    [formattedStates, filterValues],
  );
  useEffect(() => {
    setSelectedUnitName(filteredStatuses.map((mov) => mov.statusCode));
  }, [filteredStatuses]);

  const onClick = () => {
    setSubmittedLocationId(locationId);
  };

  const handleSelectStatusGroup = useCallback((status: string | null) => {
    setSelectedUnitName(status ? [status] : null);
  }, []);
  if (isLocationsLoading || isGraphLoading || isStatusLoading)
    return <Loader />;

  return (
    <PageContainer>
      <Title title={t('productPipeline')} icon={Icons.compound} />
      <div className="d-flex align-items-center gap-3">
        <Select
          options={locationItems}
          placeholder={t('chooseLocation')}
          onChange={(key) => setLocationId(key)}
          style={{ width: 300 }}
          allowClear
        />
        <Button type="primary" onClick={onClick}>
          {t('go')}
        </Button>
      </div>
      <Row gutter={16}>
        <Col lg={12} md={24}>
          <Card>
            <PipelineDataGrid
              statusDetailData={filteredStatuses}
              onSelectStatusGroup={handleSelectStatusGroup}
            />
          </Card>
        </Col>
        <Col lg={12} md={24}>
          <Card>
            <PipelineChart graphData={graphData?.entities} />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};
