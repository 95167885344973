import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { ServiceUnit } from '@/types';
import {
  useCreateServiceUnit,
  useServiceUnits,
  useUpdateServiceUnit,
} from '@/api/ServiceUnits';
import { useServiceUnitTypes } from '@/api/ServiceUnitTypes';
import { useServiceProviders } from '@/api/ServiceProviders';
import { useCountries } from '@/api/Countries';
import { BackButton } from '@/ui/components/BackButton';
import { routes } from '@/routes';
import { Card } from 'antd';
import { TransportUnitsDataGrid } from './components/TransportUnitsDataGrid/TransportUnitsDataGrid';
import { formatUpdateCompanyData, formatCreateCompanyData } from './helpers';

export const AdminTransportUnits: FC = () => {
  const { t } = useTranslate();

  const { data: serviceUnitsData, isLoading: isServiceUnitsLoading } =
    useServiceUnits();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateServiceUnit();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateServiceUnit();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const { data: serviceUnitTypesData, isLoading: isServiceUnitTypesLoading } =
    useServiceUnitTypes();
  const { data: serviceProvidersData, isLoading: isServiceProvidersLoading } =
    useServiceProviders();

  const handleCreateCompany = React.useCallback(
    (data: ServiceUnit) => {
      const formattedData = formatCreateCompanyData(data);

      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateCompany = React.useCallback(
    (newData: Partial<ServiceUnit>, oldData: ServiceUnit) => {
      const formattedData = formatUpdateCompanyData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isServiceUnitsLoading ||
    isCreateLoading ||
    isUpdateLoading ||
    isCountriesLoading ||
    isServiceUnitTypesLoading ||
    isServiceProvidersLoading
  )
    return <Loader />;

  return (
    <PageContainer>
      <BackButton
        linkTo={routes.systemAdministration}
        title={t('manageTransportUnits')}
      />

      <Card>
        <TransportUnitsDataGrid
          onTransportUnitAdd={handleCreateCompany}
          onTransportUnitChanged={handleUpdateCompany}
          transportUnits={serviceUnitsData?.entities}
          serviceUnitTypes={serviceUnitTypesData?.entities}
          serviceProviders={serviceProvidersData?.entities}
          countries={countriesData?.entities}
        />
      </Card>
    </PageContainer>
  );
};
