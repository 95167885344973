import React, { FC } from 'react';
import { getValueOrNA } from '@/ui/components/NotApplicable';
import { useTranslate } from '@/translations';

import { Descriptions } from 'antd';
import { fieldRenderers } from './helpers';
import { InspectionCardProps } from './types';
import { OtherInspectionsTable } from '../OtherInspectionsTable';

export const InspectionCard: FC<InspectionCardProps> = ({
  data,
  fields,
  column,
  layout,
  size,
}) => {
  const { t } = useTranslate();
  const columnNum = column || 3;

  return (
    <Descriptions
      layout={layout || 'vertical'}
      column={columnNum}
      size={size || 'middle'}
      items={Object.entries(fields).map(([field, label]) => {
        if (field === 'otherInspections') {
          return {
            key: field,
            label,
            span: columnNum,
            children: <OtherInspectionsTable data={data.otherInspections} />,
          };
        }

        const value = fieldRenderers[field]
          ? fieldRenderers[field](data, t)
          : getValueOrNA(field)({ data });

        return {
          key: field,
          label,
          children: value,
          span: field === 'id' ? 2 : undefined,
        };
      })}
    />
  );
};
