import styled from 'styled-components';

export const SliderContent = styled.aside<{ collapsed: boolean }>`
  flex: 0 0 ${(p) => (p.collapsed ? 80 : 250)}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: flex 0.3s;
  background: #fff;
`;
