import { useEffect } from 'react';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

import { WS_VEHICLE_INSPECTION_HUB } from '@/api/Core/endpoints/WebSockets';

export enum HUB_METHODS_NAMES {
  SendVehicleInspectionsToUser = 'SendVehicleInspectionsToUser',
}

export class SignalRConnection {
  private static instance: SignalRConnection;

  private connection: signalR.HubConnection | null = null;

  public static getInstance(): SignalRConnection {
    if (!SignalRConnection.instance) {
      SignalRConnection.instance = new SignalRConnection();
    }
    return SignalRConnection.instance;
  }

  public async initializeConnection() {
    if (this.connection) return this.connection;

    this.connection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_BASE_API_URL?.replace(
          '/api/',
          '',
        )}${WS_VEHICLE_INSPECTION_HUB}`,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        },
      )
      .build();

    await this.connection
      .start()
      .then(() => console.log('WebSocket connection initialized!'))
      .catch((err) => console.error('WebSocket connection failed!', err));

    return this.connection;
  }

  public getConnection(): signalR.HubConnection | null {
    return this.connection;
  }

  public stopConnection() {
    if (this.connection) {
      this.connection.stop();
      this.connection = null;
    }
  }
}

export const useWebSockets = (
  event: HUB_METHODS_NAMES,
  callback: (data: any) => void,
) => {
  useEffect(() => {
    const signalRInstance = SignalRConnection.getInstance();

    signalRInstance.initializeConnection().then((connection) => {
      if (connection) {
        // Register event handlers
        connection.on(event, callback);
      }
    });

    // Cleanup event handlers
    return () => {
      const connection = signalRInstance.getConnection();
      if (connection) {
        connection.off(event);
      }
    };
  }, [event, callback]);
};
