import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationGridLinkItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;

  & p {
    color: ${({ theme }) => theme.defaultLink};
    margin: 1rem 0;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.accent};
    }

    & svg {
      color: ${({ theme }) => theme.accent};
    }
  }
`;
