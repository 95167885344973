import styled from 'styled-components';

export const TNRStatusPill = styled.div<{ status: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 24px;
  padding: 5px 10px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${({ theme, status }) =>
    status === 'Eligible' ? theme.lightGreen : theme.lightRed};
`;
