import React, { FC, useState } from 'react';
import { useTranslate } from '@/translations';
import { acceptImageFiles } from '@/modules/UploadFile/utils';
import { MAX_FILE_SIZE } from '@/utils/constants';
import { useUpdateInspectionDamagesImages } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { Modal, UploadFile } from 'antd';
import { DragUpload } from '@/ui/components/DragUpload';
import { AddImagePopupProps } from './types';

export const AddImagePopup: FC<AddImagePopupProps> = ({
  isPopupVisible,
  onTogglePopup,
  vehicleDamageId,
}) => {
  const { t } = useTranslate();
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const [fileName, setFileName] = useState('');

  const { mutateAsync: updateDamagesImagesData } =
    useUpdateInspectionDamagesImages(vehicleDamageId);

  const handleUploadFile = async () => {
    await updateDamagesImagesData({
      data: {
        vehicleDamageId,
        imageFileName: fileName,
        imageFileBase64: file,
      },
    });
  };

  const handleValueChange = (files: UploadFile[]) => {
    if (files.length === 0) {
      setFile(null);
      setFileName('');
      return;
    }

    const item = files[0].originFileObj as File;
    if (!item) return;

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        setFile(target.result);
        setFileName(item.name);
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  return (
    <Modal
      open={isPopupVisible}
      onCancel={onTogglePopup}
      title={t('addImage')}
      centered
      footer={null}
    >
      <DragUpload
        accept={acceptImageFiles}
        uploadButtonText={t('upload')}
        maxFileSize={MAX_FILE_SIZE}
        onFileChanged={handleValueChange}
        onUpload={handleUploadFile}
        onFinished={onTogglePopup}
      />
    </Modal>
  );
};
