import {
  localDateFormatted,
  localDateTimeFormatted,
  localTimeFormatted,
} from '@/utils/helpers';
import { getValueOrNA } from '@/ui/components/NotApplicable';
import { InspectionStatusMap } from '@/utils/constants';

import { FieldRenderers } from './types';

export const fieldRenderers: FieldRenderers = {
  inspectionDateTime: (data) =>
    data.inspectionDateTime
      ? localDateTimeFormatted(data.inspectionDateTime)
      : getValueOrNA('inspectionDate')({ data }),
  inspectionDate: (data) =>
    data.inspectionDateTime
      ? localDateFormatted(data.inspectionDateTime)
      : getValueOrNA('inspectionDate')({ data }),
  inspectionTime: (data) =>
    data.inspectionDateTime
      ? localTimeFormatted(data.inspectionDateTime)
      : getValueOrNA('inspectionDate')({ data }),
  inspectionStatus: (data) =>
    data.inspectionStatus
      ? InspectionStatusMap[
          data.inspectionStatus as keyof typeof InspectionStatusMap
        ]
      : getValueOrNA('inspectionStatus')({ data }),
  approvalDateTime: (data) =>
    data.approvalDateTime
      ? localDateTimeFormatted(data.approvalDateTime)
      : getValueOrNA('approvalDateTime')({ data }),
};
