import { Damage, Inspection, RepairAction, SeverityActionData } from '@/types';

export type DamageDataGridDataSource = Damage;

export interface DamageDataGridProps {
  loading: boolean;
  inspection: Inspection;
  dataSource: DamageDataGridDataSource[];
  repairActionsData?: RepairAction[];
  severityActionsData?: SeverityActionData;
  onReload: () => void;
}

export enum AuthorizationStatus {
  Default,
  Approved,
  Rejected,
}
