import React from 'react';
import { useTranslate } from '@/translations';

import { NotApplicable } from './NotApplicable.styled';

type NotApplicableComponentProps = {
  value: number | string | Date | null | undefined | boolean;
};

export const NotApplicableComponent = ({
  value,
}: NotApplicableComponentProps) => {
  const { t } = useTranslate();

  if (value === undefined) {
    // field doesn't exist, need to highlight
    return <NotApplicable>{t('notApplicable')}</NotApplicable>;
  }

  if (value === null || value === '') {
    // field is empty, filled null or empty string from BE, no need to highlight
    return <span>{t('notApplicable')}</span>;
  }

  return <span>{value}</span>;
};

export const getValueOrNA =
  (field: string) =>
  ({ data }: { data: any }) =>
    <NotApplicableComponent value={data[field]} />;
