export interface AuditLog {
  id: number;
  /// <summary>
  /// who: user
  /// </summary>
  userName: string;
  /// <summary>
  /// who: tenant
  /// </summary>
  tenantName: string;
  /// <summary>
  /// who: event source
  /// </summary>
  applicationName: string;

  /// <summary>
  /// what: action
  /// </summary>
  actionType: AuditActionType;
  /// <summary>
  /// what: event
  /// </summary>
  eventType: string;
  /// <summary>
  /// what: data primary key
  /// </summary>
  eventKey: string;
  /// <summary>
  /// what: data changes
  /// </summary>
  eventData: string;

  /// <summary>
  /// where: ip
  /// </summary>
  ipAddress: string;
  /// <summary>
  /// where: location
  /// </summary>
  latitude?: number;
  /// <summary>
  /// where: location
  /// </summary>
  longitude?: number;

  /// <summary>
  /// when
  /// </summary>
  createAt: string;
}

export interface AuditLogData {
  entities: AuditLog[];
}

export enum AuditActionType {
  Create = 'Create',
  Update = 'Update',
  Read = 'Read',
  Delete = 'Delete',
  UnDelete = 'UnDelete',
}

export enum AuditLogEvent {
  ReturnInspectionVehicleDetail = 'Vehicle',
  ReturnInspectionDamageDetail = 'Damage line',
  ReturnInspectionDamageDetailAction = 'Damage line action',
  ReturnInspectionDamageDetailImages = 'Damage line image',
  ReturnInspectionOdometer = 'Odometer',
  ReturnInspectionVehicleView = 'Vehicle arrival images',
  ReturnInspectionWheelDetails = 'Wheels',
  ReturnInspectionPreInspectionData = 'Dynamic Operation',
  ReturnInspectionSummary = 'Summary',
}
