import React, { useCallback, useState, FC } from 'react';
import { DamageActionsDataGridSection } from '@/modules/Inspection/components/tabs/Damage/components/DamageActions/DamageActionsDataGridSection';
import { useTranslate } from '@/translations';
import { AddActionPopup } from '@/modules/Inspection/components/tabs/Damage/components/AddActionPopup/AddActionPopup';

import { useInspectionsVehicleDamageActions } from '@/api/Inspections';
import { Button } from 'antd';
import { faAdd, Icon } from '@/ui/components/Icon';
import { DamageActionsProps } from './types';

export const DamageActions: FC<DamageActionsProps> = ({
  inspectionId,
  vehicleDamageId,
  masterDetailsDataGridProps,
  disabled,
}) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const { data, refetch } = useInspectionsVehicleDamageActions(vehicleDamageId);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h6 className="m-0">{t('frCodes')}</h6>
        <Button shape="circle" disabled={disabled} onClick={togglePopup}>
          <Icon icon={faAdd} />
        </Button>
      </div>

      <DamageActionsDataGridSection
        inspectionId={inspectionId}
        damageActionsData={data}
        masterDetailsDataGridProps={masterDetailsDataGridProps}
        disabled={disabled}
        onReload={refetch}
      />

      <AddActionPopup
        vehicleDamageId={vehicleDamageId}
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        onAdded={refetch}
      />
    </>
  );
};
