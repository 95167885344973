import React, { memo, useCallback } from 'react';
import { Column, DataGrid, Export, Grouping, MasterDetail, Pager } from '@/ui';
import { FormattedVesselsMovementData } from '@/modules/Vessel/types';
import { isPagerVisible } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import { Icon, faEye, faUpload } from '@/ui/components/Icon';
import { useGetPathToDocumentManagement } from '@/hooks/useGetPathToDocumentManagement';
import { AppEntities } from '@/types';

import { VesselDataGridInner } from './VesselDataGridInner';
import { handleExport } from './export';

type VesselDataGridProps = {
  dataSource: FormattedVesselsMovementData[];
  onSelectVesselGroup: (mmsi: number | null) => void;
};

const PAGE_SIZE = 10;

export const VesselDataGridComponent = ({
  dataSource,
  onSelectVesselGroup,
}: VesselDataGridProps) => {
  const { getDocumentManagementPath, hasAdminAccess } =
    useGetPathToDocumentManagement();

  const onRenderCell = useCallback(
    (props) => (
      <>
        <Link
          to={getDocumentManagementPath({ entityReference: props.data.vessel })}
        >
          <Icon icon={faEye} />
        </Link>
        {hasAdminAccess && (
          <Link
            to={getDocumentManagementPath({
              modal: 'uploadDocument',
              entity: AppEntities.Vessel,
              entityReference: props.data.vessel,
            })}
            style={{ marginLeft: '8px' }}
          >
            <Icon icon={faUpload} />
          </Link>
        )}
      </>
    ),
    [getDocumentManagementPath, hasAdminAccess],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      showColumnLines
      showRowLines={false}
      showBorders
      width="100%"
      columnHidingEnabled
      allowColumnReordering
      paging={{
        pageSize: PAGE_SIZE,
      }}
      onExporting={handleExport}
      onRowExpanded={(e) => {
        onSelectVesselGroup(e.key.mmsi);
      }}
      onRowCollapsed={() => {
        onSelectVesselGroup(null);
      }}
    >
      <Grouping autoExpandAll={false} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column dataField="vessel" minWidth={60} width={160} alignment="left" />
      <Column
        dataField="despatchDate"
        minWidth={20}
        width={90}
        alignment="left"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <Column
        caption="ETA Date"
        dataField="etaDate"
        minWidth={20}
        width={90}
        alignment="left"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <Column
        dataField="locationFrom"
        minWidth={20}
        width={90}
        alignment="left"
      />
      <Column
        dataField="locationTo"
        minWidth={20}
        width={90}
        alignment="left"
      />
      <Column dataField="quantity" minWidth={20} width={85} alignment="left" />
      <Column
        dataField="Files"
        minWidth={20}
        width={60}
        alignment="center"
        cellRender={onRenderCell}
      />

      <MasterDetail
        enabled
        render={(row: { data: FormattedVesselsMovementData }) => (
          <VesselDataGridInner dataSource={row.data.items} />
        )}
      />
      <Export enabled />
    </DataGrid>
  );
};

export const VesselDataGrid = memo(VesselDataGridComponent);
