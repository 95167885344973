import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslate } from '@/translations';
import { localDateTimeFormatted } from '@/utils/helpers';
import {
  InspectionStatusMap,
  InspectionStatusMapLookupFilterValues,
  StorageKeys,
} from '@/utils/constants';
import { paths } from '@/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LicensePlate } from '@/ui/components/LicensePlate';
import { useGetUserRole } from '@/hooks';

import { faAngleRight, Icon } from '@/ui/components/Icon';
import { inspections, useInspectionsVehiclesFilters } from '@/api/Inspections';
import { AppSearch, Inspection } from '@/types';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { defaultPageSize } from './constants';
import { InspectionIdContainer } from './InspetionsDataGrid.styled';

export const InspectionsDataGrid: FC = () => {
  const { t } = useTranslate();
  const { Member } = useGetUserRole();
  const { data: filterOptions, isLoading: filtersLoading } =
    useInspectionsVehiclesFilters();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Inspection[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<AppSearch<Inspection>>();
  const defaultAppSearch: AppSearch<Inspection> = useMemo(
    () => ({
      name: undefined,
      page: 1,
      pageSize: defaultPageSize,
      filters: [],
      sortField: 'inspectionDateTime',
      sortOrder: 'descend',
    }),
    [],
  );

  const getInspections = async (queryData: AppSearch<Inspection>) => {
    setLoading(true);
    queryData.filters?.forEach((filter) => {
      filter.value = filter.value.map((v) =>
        v instanceof Date ? v.toISOString() : v?.toString(),
      );
    });
    const response = await inspections.postInspectionsVehicles(queryData);
    setLoading(false);
    return response?.data || {};
  };

  const loadData = useCallback(async (queryData: AppSearch<Inspection>) => {
    const response = await getInspections(queryData);

    const entities = response?.entities || [];
    const totalCount = response?.totalCount || 0;
    if ((queryData.page || 1) > 1 && entities.length === 0) {
      const newQuery = { ...queryData };
      newQuery.page = 1;
      newQuery.pageSize = defaultPageSize;
      setQuery(newQuery);
    } else {
      setData(entities);
      setTotal(totalCount);
    }
  }, []);

  useEffect(() => {
    if (query) loadData(query);
  }, [loadData, query]);

  const quickViewFormat = useCallback((queryInfo: AppSearch<Inspection>) => {
    const dateFields = ['inspectionDateTime'];
    queryInfo.filters = queryInfo.filters?.map((filter) => {
      if (dateFields.includes(filter.key as string)) {
        filter.value = filter.value.map((v) => new Date(v));
      }
      return filter;
    });
  }, []);

  const getExportData = async () => {
    const exportQuery = { ...query };
    exportQuery.page = undefined;
    exportQuery.pageSize = undefined;
    const response = await getInspections(exportQuery as any);
    return response?.entities || [];
  };

  // Load search params from URL, only trigger once
  useEffect(() => {
    if (query) return;

    const view = searchParams.get('view');
    const localSetting = localStorage.getItem(
      `${StorageKeys.SearchInspections}${view ? `-${view}` : ''}`,
    );
    const cachedQuery: AppSearch<Inspection> = localSetting
      ? (JSON.parse(localSetting) as AppSearch<Inspection>)
      : defaultAppSearch;
    if (!cachedQuery.page) cachedQuery.page = 1;
    if (!cachedQuery.pageSize) cachedQuery.pageSize = defaultPageSize;
    quickViewFormat(cachedQuery);
    setQuery(cachedQuery);
  }, [defaultAppSearch, query, quickViewFormat, searchParams]);

  const columns: CustomColumns<Inspection> = useMemo(
    () => [
      {
        dataIndex: 'id',
        title: t('inspectionId'),
        renderType: 'number',
        width: 130,
        render: (value, record) => (
          <InspectionIdContainer
            onClick={() => {
              navigate(
                `/${paths.inspection}/${value}?inspectionStatus=${record.inspectionStatus}`,
              );
            }}
          >
            {value} <Icon icon={faAngleRight} />
          </InspectionIdContainer>
        ),
        exportRender: (value) => value.toString(),
        sorter: true,
        filter: true,
        fixed: 'left',
      },
      {
        dataIndex: 'regNumber',
        title: t('registration'),
        width: 150,
        align: 'center',
        sorter: true,
        filter: true,
        render: (value) =>
          value ? <LicensePlate>{value}</LicensePlate> : t('noRegNumber'),
        exportRender: (value) => value || t('noRegNumber'),
      },
      {
        dataIndex: 'vin',
        title: t('vin'),
        sorter: true,
        filter: true,
        ellipsis: true,
      },
      {
        dataIndex: 'fleetDescription',
        title: t('customer'),
        sorter: true,
        filter: true,
        ellipsis: true,
      },
      {
        dataIndex: 'inspectionLocation',
        title: t('inspectedAt'),
        renderType: 'select',
        sorter: true,
        filter: true,
        filterOptions: filterOptions?.inspectionLocations || [],
      },
      {
        dataIndex: 'fleetCode',
        title: t('fleetCode'),
        renderType: 'select',
        width: 110,
        sorter: true,
        filter: true,
        filterOptions: filterOptions?.fleetCodes || [],
      },
      {
        dataIndex: 'inspectionType',
        title: t('inspectionType'),
        renderType: 'select',
        width: 100,
        sorter: true,
        filter: true,
        filterOptions: filterOptions?.inspectionTypes || [],
        ellipsis: true,
      },
      {
        dataIndex: 'model',
        title: t('model'),
        width: 150,
        sorter: true,
        filter: true,
        ellipsis: true,
      },
      {
        dataIndex: 'inspectionDateTime',
        title: t('inspectionDate'),
        renderType: 'date',
        width: 180,
        sorter: true,
        filter: true,
        render: (value) =>
          localDateTimeFormatted(value, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          }),
      },
      {
        dataIndex: 'inspectionStatus',
        title: t('inspectionStatus'),
        renderType: 'select',
        width: 150,
        sorter: true,
        filter: true,
        filterOptions: (Member
          ? [InspectionStatusMapLookupFilterValues[1]]
          : InspectionStatusMapLookupFilterValues
        ).map((o) => ({ text: o.displayExpr, value: o.id })),
        render: (value) =>
          InspectionStatusMap[value as keyof typeof InspectionStatusMap],
      },
      {
        dataIndex: 'inspector',
        title: t('inspector'),
        renderType: 'select',
        sorter: true,
        filter: true,
        filterOptions: filterOptions?.inspectors || [],
      },
    ],
    [Member, filterOptions, navigate, t],
  );

  return (
    <CustomTable
      dataSource={data}
      columns={columns}
      rowKey="id"
      loading={loading || filtersLoading}
      pagination={{
        total,
        pageSize: query?.pageSize,
        current: query?.page || 1,
      }}
      exporting
      exportFileName="Inspections"
      getExportData={getExportData}
      quickView
      quickViewFormat={quickViewFormat}
      appSearchStorage={StorageKeys.SearchInspections}
      defaultAppSearch={defaultAppSearch}
      appSearch={query}
      onAppSearchChange={(search) => {
        if (search.name) {
          searchParams.set('view', search.name);
        } else {
          searchParams.delete('view');
        }
        setQuery(search);
      }}
      scroll={{ x: 1800 }}
    />
  );
};
