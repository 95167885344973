import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useShippingPlan } from '@/api/ShippingPlan';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faCalendar } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import { Card } from 'antd';
import { ShippingPlanDataGrid } from './components/ShippingPlanDataGrid';

export const ShippingPlan = () => {
  const { t } = useTranslate();
  const { shippingPlanLink } = useTheme();
  const idTokenClaims = useIdTokenClaims();
  const tenant = idTokenClaims?.appTenantId;
  const { data, isLoading } = useShippingPlan(tenant);

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Title
        title={t('shippingPlan')}
        icon={faCalendar}
        color={shippingPlanLink}
      />
      <Card>
        <ShippingPlanDataGrid dataSource={data?.entities} />
      </Card>
    </PageContainer>
  );
};
