import React, { FC, memo, useCallback } from 'react';
import {
  Column,
  DataGrid,
  Export,
  LoadPanel,
  MasterDetail,
  Paging,
  Pager,
  Grouping,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { Link } from 'react-router-dom';
import { Icon, faEye, faUpload } from '@/ui/components/Icon';
import { useGetPathToDocumentManagement } from '@/hooks/useGetPathToDocumentManagement';
import { AppEntities } from '@/types';
import { useTranslate } from '@/translations';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';

import { TransportMovementsDataGridProps } from './types';
import { TransportMovementGridDetailSection } from '../TransportMovementGridDetailSection';
import { FormattedTransportMovementData } from '../../types';
import { handleExport } from './export';

const PAGE_SIZE = 10;

export const TransportMovementDataGrid: FC<TransportMovementsDataGridProps> = ({
  dataSource,
}) => {
  const { t } = useTranslate();
  const { getDocumentManagementPath, hasAdminAccess } =
    useGetPathToDocumentManagement();

  const renderGridCell = useCallback(
    (props) => (
      <>
        <Link
          to={getDocumentManagementPath({
            entityReference: props.data.unitName,
          })}
        >
          <Icon icon={faEye} />
        </Link>
        {hasAdminAccess && (
          <Link
            to={getDocumentManagementPath({
              modal: 'uploadDocument',
              entity: AppEntities.Transport,
              entityReference: props.data.unitName,
            })}
            style={{ marginLeft: '8px' }}
          >
            <Icon icon={faUpload} />
          </Link>
        )}
      </>
    ),
    [getDocumentManagementPath, hasAdminAccess],
  );

  const renderActionsCell = useCallback(
    () => (
      <StyledLinkContainer>
        <StyledLink>{t('confirm')}</StyledLink>
        <StyledLink>{t('dispatch')}</StyledLink>
      </StyledLinkContainer>
    ),
    [t],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr={['movementId', 'unitName']}
      columnHidingEnabled
      width="100%"
      showColumnLines
      showRowLines={false}
      showBorders
      allowColumnReordering
      onExporting={handleExport}
    >
      <Grouping autoExpandAll={false} />
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column dataField="movementId" minWidth={20} visible={false} />
      <Column
        dataField="movementRef"
        caption="Load #"
        minWidth={115}
        alignment="left"
      />
      <Column
        dataField="etaDate"
        caption="Load Requested Date Time"
        minWidth={160}
        width={160}
        dataType="datetime"
        format={FORMAT_DATE_WITH_TIME}
      />
      <Column
        dataField="etdDate"
        caption="Load Pickup Date Time"
        minWidth={160}
        width={160}
        dataType="datetime"
        format={FORMAT_DATE_WITH_TIME}
      />
      <Column
        dataField="loadLane"
        caption="Load Lane"
        minWidth={160}
        width={160}
      />
      <Column
        dataField="despatchDate"
        caption="Despatch Date"
        minWidth={160}
        width={160}
        dataType="datetime"
        format={FORMAT_DATE_WITH_TIME}
      />
      <Column
        dataField="locationFromCity"
        caption="From Location"
        minWidth={100}
        width={100}
      />
      <Column
        dataField="locationToCity"
        caption="To Location"
        minWidth={100}
        width={100}
      />
      <Column
        dataField="statusDesc"
        caption="Status"
        minWidth={100}
        width={100}
      />
      <Column
        dataField="providerName"
        caption="Haulier"
        minWidth={80}
        width={80}
      />
      <Column
        dataField="unitName"
        caption="Haulier Reg"
        minWidth={110}
        width={110}
      />
      <Column dataField="quantity" caption="Qty" minWidth={50} width={50} />
      <Column
        dataField="Files"
        minWidth={80}
        width={80}
        alignment="center"
        cellRender={renderGridCell}
      />
      <Column
        dataField="actions"
        caption={null}
        cellRender={renderActionsCell}
        alignment="center"
        allowSorting={false}
        allowEditing={false}
        allowSearch={false}
        allowFiltering={false}
        width={140}
      />
      <MasterDetail
        enabled
        render={(row: { data: FormattedTransportMovementData }) => (
          <TransportMovementGridDetailSection dataSource={row.data.items} />
        )}
      />
      <Export enabled />
    </DataGrid>
  );
};

export const TransportDataGrid = memo(TransportMovementDataGrid);
