import styled from 'styled-components';

export const CustomCardContent = styled.div<{
  direction?: 'row' | 'column';
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: ${({ theme }) => theme.defaultGrayBgColor};
`;
