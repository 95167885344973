import { useTranslate } from '@/translations';
import { Inspection } from '@/types';
import React, { FC } from 'react';
import { StyledLink } from '@/ui/globalStyles';
import { paths } from '@/routes';
// import { useNavigate } from 'react-router-dom';
import { fieldRenderers } from '../InspectionCard/helpers';

interface OtherInspectionsTableProps {
  data: Inspection[];
}
export const OtherInspectionsTable: FC<OtherInspectionsTableProps> = ({
  data,
}) => {
  // const navigate = useNavigate();
  const { t } = useTranslate();

  return data.length ? (
    <div style={{ maxHeight: 200, overflowY: 'auto' }}>
      <table className="w-100">
        <thead>
          <tr>
            <th className="fw-semibold" style={{ width: 80 }}>
              ID
            </th>
            <th className="fw-semibold">Site</th>
            <th className="fw-semibold">Date</th>
            <th className="fw-semibold">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((inspection) => (
            <tr key={inspection.id}>
              <td>
                <StyledLink
                  onClick={
                    () =>
                      window.open(
                        `/${paths.inspection}/${inspection.id}?inspectionStatus=${inspection.inspectionStatus}`,
                        '_blank',
                      )
                    // navigate(
                    //   `/${paths.inspection}/${inspection.id}?inspectionStatus=${inspection.inspectionStatus}`,
                    //   { replace: true },
                    // )
                  }
                >
                  {inspection.id}
                </StyledLink>
              </td>
              <td className="text-muted">{inspection.inspectionLocation}</td>
              <td className="text-muted">
                {fieldRenderers.inspectionDate(inspection, t)}
              </td>
              <td className="text-muted">
                {fieldRenderers.inspectionStatus(inspection, t)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};
