import styled from 'styled-components';

export const HeaderContent = styled.header`
  position: fixed;
  width: 100%;
  height: 96px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
